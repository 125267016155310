import axios from "axios";
import Utils from "../../utility";
// import { getToken } from "../../utility/constant";
import {
  getUserIdFromLs,
  // getUserNameFromLs,
} from "../../utility/commonFunction";
import moment from "moment";
export const getDashboardData = (values) => {
  return (dispatch) => {
    const {
      storeId,
      // storeName,
      // productName,
      state,
      city,
      toDate,
      fromDate,
      // productId,
    } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.dashboard,
      `${storeId ? `?store=${storeId}` : ""}${
        fromDate ? `&fromdate=${fromDate}` : ""
      }${toDate ? `&toDate=${toDate}` : ""}${state ? `&state=${state}` : ""}${
        city ? `&city=${city}` : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboard: respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getDashboardEmbedUrl = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // axios
    //   .post(
    //     "https://paytm.thenewshop.in/v2/api/v1/quicksight",
    //     {
    //       dashboard_id: id,
    //     },
    //     {
    //       headers: {
    //         Authorization: getToken(),
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {});
    // &userType=1
    Utils.api.postApiCall(
      `${
        window.location.href.includes("https://admin.thenewshop.in")
          ? "https://services.thenewshop.in/"
          : "https://uatservices.thenewshop.in/"
      }api/v1/quicksight`,
      {
        dashboard_id: id,
      },
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            url: respData.data?.value?.EmbedUrl,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(
        //   2,
        //   `quicksight POST Method error ${
        //     JSON.stringify(data?.message) || data?.error
        //   }`
        // );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getDashboardTrend = (storeId) => {
  // debugger;
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      "https://analytics.thenewshop.in/dashboard-trends",
      { storeId, userId: getUserIdFromLs() },
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboardChartData: respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `dashboard-trends POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getDashboardDetail = (values) => {
  // debugger;
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { storeId, toDate, fromDate } = values;
    const dataToSend = {
      storeId: storeId || "*",
      userId: getUserIdFromLs(),
      fromDate: moment(fromDate).format("YYYY-MM-DD") + " 00:00:00",
      toDate: moment(toDate).format("YYYY-MM-DD") + " 23:59:59",
    };
    Utils.api.postApiCall(
      "https://analytics.thenewshop.in/dashboard-detail",
      dataToSend,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboardDetailData:
              respData.data == "NO PAGE NOT FOUND" ? [] : respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `dashboard-detail POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
