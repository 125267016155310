import Utils from "../../../utility";
import { getUserTypeFromLs } from "../../../utility/commonFunction";
export const getStoreList = (takes) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.store,
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${
        takes ? takes : take
      }&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            storeList: respData.data.stores,
            count: respData.data.count,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getStoreDetail = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.store,
      `${id}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            storeDetail: respData.data,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getStoreListForDropdown = (navigate) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, search } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.store,
      `?order=${order}&sortField=${sortField}&offset=${offset}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            storeListForDropdown: respData.data.stores,
          },
        });
        if (getUserTypeFromLs() == Utils.constants.UserTypeObj.PO) {
          const biller = respData?.data?.stores.map((item) =>
            item.biller.map((x) => x.biller)
          );
          dispatch({
            type: Utils.ActionName.COMMON,
            payload: {
              userlistForDropdown: biller?.flat(),
            },
          });
        }
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        if (data?.statusCode === 1102) {
          navigate("/payment-royalty");
        }
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const updateStore = (values, setOpen, id, resetForm, setIsEdit) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = values;
    dataToSend.isDeleted = false;

    // dataToSend.runner = +values.runner;
    dataToSend.propertyOwner = values.propertyOwner;
    dataToSend.pincode = values.pincode;
    dataToSend.operator = values?.manager;

    // dataToSend.manager = "";
    // dataToSend.operator = `${values?.dataToSend ? values?.dataToSend : 0}`;
    // dataToSend.propertyOwner = `${values?.propertyOwner}`;
    // dataToSend.FSSAI=values.FSSAI
    // dataToSend.FSSAI = +values.FSSAI;
    if (
      !dataToSend?.propertyOwner ||
      dataToSend?.propertyOwner == "undefined"
    ) {
      delete dataToSend?.propertyOwner;
    }
    //
    // return;
    // dataToSend.propertyOwner = `${values?.propertyOwner}`;

    Utils.api.putApiCall(
      Utils.endPoints.store + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Store updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        dispatch(getStoreList());
        dispatch(getStoreListForDropdown());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.store} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);

        // setSubmitting(true);
      }
    );
  };
};
export const createStores = (values, setOpen) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = { ...values };
    dataToSend.isDeleted = false;
    dataToSend.runner = values.runner;
    dataToSend.propertyOwner = values.propertyOwner;
    // dataToSend.FSSAI = +values.FSSAI;
    dataToSend.operator = values?.manager;

    // return;
    // dataToSend.propertyOwner = `${values?.propertyOwner}`;
    if (dataToSend?.manager) {
      delete dataToSend?.manager;
    }
    // if (!dataToSend?.propertyOwner) {
    //   delete dataToSend?.propertyOwner;
    // }
    Utils.api.postApiCall(
      Utils.endPoints.createStore,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Store updated successfully");
        dispatch(getStoreList());
        dispatch(getStoreListForDropdown());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createStore} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const deleteStore = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.store + `${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Store delete sucesfuly");
        dispatch(getStoreList());
        dispatch(getStoreListForDropdown());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.store} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

export const getBiller = (takes) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.biller,
      "",
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            billerList: respData.data,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.biller} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
/**get biller by id */

export const getBillerById = (id, resp) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.biller,
      `/${id}`,
      (respData) => {
        resp(respData?.data);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.biller} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getRunnerList = (takes) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      "users?order=ASC&sortField=userType&offset=1&take=50&search=R",
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            runnerList: respData.data?.users,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getOprationManagerList = (takes) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } = getState().storeReducer;
    if (
      Utils.constants.getUsertypeFromLS() !== Utils.constants.UserTypeObj.SA
    ) {
      return;
    }
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.oprationManager,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            oprationManagerList: respData.data,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.oprationManager} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * create order for rozapay
 */

export const creatOrderForPayment = (values, callback) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = {
      invoiceNumber: "inoice_20",
      amount: `${values?.amount}`,
      notes: "strindsfsg",
      storeId: values?.storeId,
    };

    // if (!dataToSend?.propertyOwner) {
    //   delete dataToSend?.propertyOwner;
    // }
    Utils.api.postApiCall(
      Utils.endPoints.razorPayOrder,
      dataToSend,
      (respData) => {
        // setOpen(false);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(1, "Store updated successfully");
        callback(respData?.data);
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createStore} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get storewise royalty
 */

export const getStoreRoyalty = (navigate) => {
  return (dispatch, getState) => {
    const {
      order,
      sortField = "royaltyPending",
      offset,
      status,
      take,
      search,
      storeId,
    } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.storeRoyalty,
      `?store=${storeId || ""}&status=${
        status || ""
      }&order=${order}&offset=${offset}&take=${take}&sort=${"royaltyPending"}`,
      (respData) => {
        if (navigate) window.location.replace("/store-royalty");
        // navigate("");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            monthWiseRoyalty: respData.data,
            count: respData.data.count,
          },
        });
        if (respData.data?.length) {
          // localStorage.setItem("royaltyPending", 1);
          dispatch({
            type: Utils.ActionName.COMMON,
            payload: { royaltyPending: true },
          });
        }
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**get royalty history */

export const getStoreRoyaltyHistory = () => {
  return (dispatch, getState) => {
    const { storeId } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.storeRoyalty,
      `/${storeId}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            royaltyHistory: respData.data,
            count: respData.data.count,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get history detail page
 */

export const getStoreRoyaltyHistoryDetail = (id) => {
  return (dispatch, getState) => {
    // const { id } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.royaltyDetail,
      `${id}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            royaltyHistoryDetail: respData.data,
            // count: respData.data.count,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * Get cash pending edit
 */
export const editCashPending = (values, setopen) => {
  return (dispatch, getState) => {
    const { storeId, cashPending } = values;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.cashPendingEdit + storeId,
      {
        cashPending,
      },
      (respData) => {
        setopen(false);
        dispatch(getStoreList());
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: { loading: false },
        // });
        // dispatch({
        //   type: Utils.ActionName.STORE,
        //   payload: {
        //     royaltyHistoryDetail: respData.data,
        //     // count: respData.data.count,
        //   },
        // });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.store} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
