import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";

import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";

import { makeATransfers2s } from "./action";
import Input from "../../../component/input";
import { Box, Grid } from "@mui/material";

import Select from "../../../component/select";
import NormalButton from "../../../component/button";
const AddUser = ({
  setOpen,
  // setOpenMakeTransfer,
}) => {
  const dispatch = useDispatch();
  const { productsDropDownList } = useSelector((state) => state.commonReducer);

  const initialValues = {
    stocks: [
      {
        product: null,
        quantity: null,
      },
    ],
    storeTo: null,
    store: null,
  };

  const { storeListForDropdown } = useSelector((state) => state.commonReducer);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.makeStockTransferS2S}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // if (isEdit?.length) {
        //   dispatch(
        //     updateStoreStock(
        //       values,
        //       setOpen,
        //       isEdit[0].id,
        //       resetForm,
        //       setIsEdit
        //     )
        //   );
        // } else {
        //   dispatch(makeATransfers2s(values, setOpen));
        // }
        dispatch(makeATransfers2s(values, setOpen, setSubmitting));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                handleChange={handleChange}
                value={values.storeTo}
                label={"Select Store"}
                option={
                  storeListForDropdown?.length &&
                  storeListForDropdown.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      {item?.storename}
                    </MenuItem>
                  ))
                }
                name="storeTo"
                error={errors.storeTo}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                handleChange={handleChange}
                value={values.store}
                label={"Select store"}
                option={
                  storeListForDropdown?.length &&
                  storeListForDropdown.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      {item?.storename}
                    </MenuItem>
                  ))
                }
                name="store"
                error={errors.store}
              />
            </Grid>
          </Grid>
          <hr className="m-t-20" />
          <FieldArray
            name="stocks"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.stocks && values.stocks.length > 0
                    ? values.stocks.map((val, index) => (
                        <>
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={2}
                            className="m-t-5"
                          >
                            <Grid item xs={12} sm={6} lg={4}>
                              <React.Fragment
                                key={values?.stocks[index].product}
                              >
                                <AutoComplete
                                  freeSolo={false}
                                  // onBlur={handleBlur}
                                  values={values?.stocks[index].product}
                                  onChange={(event, value) => {
                                    if (value !== null) {
                                      const pIndex =
                                        productsDropDownList.findIndex(
                                          (x) => x.id === value.id
                                        );
                                      setFieldValue(
                                        `stocks.${index}.product`,
                                        value?.id
                                      );

                                      // handleChange(event);
                                    }
                                  }}
                                  helperText={
                                    touched.product &&
                                    errors?.stocks[index]?.product
                                  }
                                  error={Boolean(
                                    touched.product &&
                                      errors?.stocks[index]?.product
                                  )}
                                  name={`stocks.${index}.product`}
                                  label={"Product Id"}
                                  // touched={touched.product}
                                  options={productsDropDownList}
                                  getOptionLabel={(option) =>
                                    `${option?.barcode} | ${option.name}`
                                  }
                                  // isRequired={true}
                                  getOptionSelected={(option, value) => {
                                    return `${option.id}` === `${value.id}`;
                                  }}
                                  defaultValue={() => {
                                    const pIndex =
                                      productsDropDownList.findIndex(
                                        (x) =>
                                          x?.id == values.stocks[index].product
                                      );
                                    return productsDropDownList[pIndex];
                                  }}
                                />
                              </React.Fragment>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                              <Input
                                value={values.stocks[index].quantity}
                                name={`stocks.${index}.quantity`}
                                type="number"
                                error={
                                  errors.stocks &&
                                  errors.stocks[index]?.quantity
                                }
                                // error={errors.product}
                                label="Inv. Quantity"
                                onChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                              <IconButton
                                style={{
                                  background: "rgb(231 112 112)",
                                  color: "white",
                                  width: 35,
                                  height: 35,
                                  borderRadius: 5,
                                }}
                                aria-label="delete"
                                size="small"
                                // color= "primary"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>

                              <IconButton
                                style={{
                                  background: "green",
                                  color: "white",
                                  width: 35,
                                  marginLeft: 5,
                                  height: 35,
                                  borderRadius: 5,
                                }}
                                aria-label="delete"
                                size="small"
                                // color= "primary"
                                onClick={() =>
                                  arrayHelpers.push({
                                    barcode: null,
                                    qunatity: null,
                                  })
                                }
                              >
                                <AddBoxTwoToneIcon fontSize="inherit" />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <hr className="m-t-20 " />
                        </>
                      ))
                    : null}
                </div>
              );
            }}
          />

          <div className="Dflex al-cnt fl-end">
            <NormalButton
              className="btn-simple"
              buttonText="Cancel"
              onPress={() => setOpen(false)}
            />
            <NormalButton
              className="btn-purple m-l-30"
              buttonText={"Submit"}
              // onPress={() => {}}
              type="submit"
            />
          </div>
          {/* <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit">
              Submit
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box> */}
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
