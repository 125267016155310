import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { createPO } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
import Select from "../../../component/select";
const AddUser = ({ setOpen, isEdit, setIsEdit, storeId }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const [grandTotal, setGrandTotal] = useState(0);
  const initialValues = {
    product: [
      {
        product: null,
        productSellingPrice: "",
        productCostPrice: "",
        quantity: "",
        total: 0,
      },
    ],
    type: isEdit?.length ? isEdit[0]?.invoice : "",
    expiryDate: null,
    deliveryDate: null,
    store: 0,
    vendor: 0,
    terms: "",
    // vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
  };
  const calculateGrandTotal = (values, index, currentValue, currentField) => {
    const grandTotal = values.reduce((total, item, i) => {
      if (i === index) {
        if (currentField === "productCostPrice")
          total = +currentValue * values[index].quantity + total;
        else total = +currentValue * values[index].productCostPrice + total;
      } else {
        total = item.total + total;
      }
      return total;
    }, 0);
    setGrandTotal(grandTotal);
  };
  // const { storeList } = useSelector((state) => state.storeReducer);
  const {
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  // const { brandOwnerList } = useSelector((state) => state.brandOwnerReducer);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Schema.storeStockSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(createPO(values, setOpen, storeId, setSubmitting));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            {/* <Select
              handleChange={handleChange}
              value={values.vendor}
              label={"vendor Id"}
              option={brandOwnerListForDropdown.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  {item?.alias}
                </MenuItem>
              ))}
              name="vendor"
              error={errors.vendor}
            /> */}
            <AutoComplete
              freeSolo={false}
              // onBlur={handleBlur}
              values={values.vendor}
              onChange={(event, value) => {
                if (value !== null) {
                  const pIndex = brandOwnerListForDropdown.findIndex(
                    (x) => x.id === value.id
                  );
                  setFieldValue(`vendor`, value?.id);

                  // handleChange(event);
                }
              }}
              helperText={touched?.vendor && errors?.vendor}
              error={Boolean(touched.vendor && errors?.vendor)}
              name={`vendor`}
              label={"Vendor"}
              // touched={touched.product}
              options={brandOwnerListForDropdown}
              getOptionLabel={(option) => `${option?.alias}`}
              // isRequired={true}
              getOptionSelected={(option, value) => {
                return `${option.id}` === `${value.id}`;
              }}
              defaultValue={() => {
                const pIndex = brandOwnerListForDropdown.findIndex(
                  (x) => x?.id == values.vendor
                );
                return brandOwnerListForDropdown[pIndex];
              }}
            />
            <Select
              handleChange={handleChange}
              value={values.store}
              label={"Store Name"}
              option={
                storeListForDropdown?.length &&
                storeListForDropdown.map((item) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {item?.storename}
                  </MenuItem>
                ))
              }
              name="store"
              error={errors.store}
            />
            <Input
              value={values.terms}
              name="terms"
              type="text"
              error={errors.terms}
              label="Terms"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          {/* <Box sx={{ display: "flex", mb: 2 }}> */}
          <Box sx={{ display: "flex", mb: 2 }}>
            <DatePicker
              value={values.deliveryDate}
              name="deliveryDate"
              handleChange={(newVal) => setFieldValue("deliveryDate", newVal)}
              label="Delivery Date"
              error={errors.deliveryDate}
            />
            <DatePicker
              value={values.expiryDate}
              name="expiryDate"
              handleChange={(newVal) => setFieldValue("expiryDate", newVal)}
              label="Expiry Date"
              error={errors.expiryDate}
            />
            <Select
              handleChange={handleChange}
              value={values.type}
              label={"PO Type"}
              option={["one-time", "reccuring"].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              name="type"
              error={errors.type}
            />
          </Box>
          <FieldArray
            name="product"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.product && values.product.length > 0
                    ? values.product.map((val, index) => (
                        <Box sx={{ display: "flex", mb: 2 }} key={index}>
                          <AutoComplete
                            freeSolo={false}
                            // onBlur={handleBlur}
                            values={values.product[index].product}
                            onChange={(event, value) => {
                              if (value !== null) {
                                const pIndex = productsDropDownList.findIndex(
                                  (x) => x.id === value.id
                                );
                                setFieldValue(
                                  `product.${index}.product`,
                                  value?.id
                                );

                                // handleChange(event);
                                if (pIndex >= 0) {
                                }
                              }
                            }}
                            helperText={
                              touched?.product &&
                              errors?.product &&
                              errors?.product[index]?.product
                            }
                            error={Boolean(
                              touched?.product &&
                                errors?.product &&
                                errors?.product[index]?.product
                            )}
                            name={`product.${index}.product`}
                            label={"Product Id"}
                            // touched={touched.product}
                            options={productsDropDownList}
                            getOptionLabel={(option) =>
                              `${option?.barcode} | ${option.name}`
                            }
                            // isRequired={true}
                            getOptionSelected={(option, value) => {
                              return `${option.id}` === `${value.id}`;
                            }}
                            defaultValue={() => {
                              const pIndex = productsDropDownList.findIndex(
                                (x) => x?.id == values.product[index].product
                              );
                              return productsDropDownList[pIndex];
                            }}
                          />
                          <Input
                            value={values.product[index].quantity}
                            name={`product.${index}.quantity`}
                            type="number"
                            error={
                              errors.product && errors.product[index]?.quantity
                            }
                            label="Quantity"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue(
                                `product.${index}.total`,
                                +e.target.value *
                                  values.product[index].productCostPrice
                              );

                              calculateGrandTotal(
                                values.product,
                                index,
                                e.target.value,
                                "quantity"
                              );
                            }}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].productSellingPrice}
                            name={`product.${index}.productSellingPrice`}
                            type="number"
                            error={
                              errors.product &&
                              errors.product[index]?.productSellingPrice
                            }
                            // error={errors.product}
                            label="Selling Price"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].productCostPrice}
                            name={`product.${index}.productCostPrice`}
                            type="number"
                            // error={errors.product}
                            error={
                              errors.product &&
                              errors.product[index]?.productCostPrice
                            }
                            label="Cost Price"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue(
                                `product.${index}.total`,
                                +e.target.value * values.product[index].quantity
                              );

                              calculateGrandTotal(
                                values.product,
                                index,
                                e.target.value,
                                "productCostPrice"
                              );
                            }}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].total}
                            name={`product.${index}.total`}
                            type="number"
                            disabled={true}
                            error={
                              errors.product && errors.product[index]?.total
                            }
                            // error={errors.product}
                            label="Total"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <IconButton
                            style={{
                              background: "rgb(231 112 112)",
                              color: "white",
                              width: 35,
                              height: 35,
                              borderRadius: 5,
                            }}
                            aria-label="delete"
                            size="small"
                            // color= "primary"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))
                    : null}
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Box
                      sx={{
                        width: "100%",
                        pr: 2,
                        maxWidth: "100%",
                        textAlign: "right",
                      }}
                    >
                      Grand Total
                    </Box>

                    <Input
                      value={grandTotal}
                      disabled
                      name="grandTotal"
                      type="number"
                      // error={errors.batch}
                      label="Grand Total "
                    />

                    <IconButton
                      style={{
                        background: "green",
                        color: "white",
                        width: 35,
                        height: 35,
                        borderRadius: 5,
                      }}
                      aria-label="delete"
                      size="small"
                      // color= "primary"
                      onClick={() =>
                        arrayHelpers.push({
                          product: null,
                          quantity: null,
                          recievedQuantity: null,
                          productCostPrice: null,
                          total: 0,
                          landingPrice: null,
                        })
                      }
                    >
                      <AddBoxTwoToneIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </div>
              );
            }}
          />

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit" disabled={isSubmitting}>
              {isEdit?.length ? "update GRN" : "Add PO"}
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
