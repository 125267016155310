import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateVersion, getAppVersion } from "./action";
import Input from "../../../component/input";
import { Box, Button } from "@mui/material";
import Breadcrumbs from "../../../component/breadcrumb";
import AddButton from "../../../component/button/buttonWithIcon";

const AddUser = () => {
  const dispatch = useDispatch();

  const [appVersion, setAppversion] = useState("");
  useEffect(() => {
    dispatch(getAppVersion((res) => setAppversion(res?.data?.version)));
  }, []);
  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: "flex", mb: 2 }}>
        <Input
          value={appVersion}
          name="appVersion"
          type="text"
          //   error={errors.terms}
          label="App Version"
          onChange={(e) => setAppversion(e.target.value)}
          //   handleBlur={handleBlur}
        />
        <div className="Dflex table-filter-head ">
          <AddButton
            text="Update Version"
            className="btn-purple m-l-10 h-100"
            onPress={() => dispatch(updateVersion(appVersion))}
          />
        </div>
      </Box>

      <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}></Box>
    </>
  );
};

export default AddUser;
