import React from "react";
// import {  } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/New Logo .jpg";
import NormalButton from "../../component/button";

// import { emailValidator, passwordValidator } from "../../utility/utils";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
// import Button from "../../component/button"
import Card from "@mui/material/Card";
import { onSubmit } from "./action";
import Input from "../../component/input";
const LoginPage = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const [emailId, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [isPasswordValid, setPasswordValid] = useState("");
  // const [isUsernameValid, setUsernameValid] = useState("");
  // const routeChange = () => {
  //   let path = `/forgot-password`;
  //   navigate(path);
  // };

  // const validateFields = (event) => {
  //   if (event.target.name === "email") {
  //     setUsernameValid(emailValidator(emailId));
  //   }

  //   if (event.target.name === "password") {
  //     setPasswordValid(passwordValidator(password));
  //   }
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!password || !emailId) {
  //     !emailId && setUsernameValid("Email is requir");
  //     !password && setPasswordValid("password is requir");
  //     return;
  //   }
  //   dispatch(onSubmit({ email: emailId, password }, navigate));
  // };
  return (
    <Box sx={{ mt: 10 }}>
      <Card
        style={{
          width: 700,
          margin: "0 auto",
        }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "User name is Required";
            }
            if (!values.password) {
              errors.password = "Password is required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            //  setTimeout(() => {
            //    alert(JSON.stringify(values, null, 2));
            //    setSubmitting(false);
            //  }, 400);
            dispatch(onSubmit(values, navigate));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  m: 3,
                }}
              >
                <img width={400} src={Logo} alt="The new Shop" />
              </Box>
              <CardContent>
                <Box>
                  <Input
                    value={values.email}
                    name="email"
                    type="text"
                    error={errors.email}
                    label="Username"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Input
                    value={values.password}
                    name="password"
                    type="password"
                    error={errors.password}
                    label="Password"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
              </CardContent>

              <div className="Dflex">
                <NormalButton
                  className="btn-purple m-l-15 w-100 m-r-15 m-b-40"
                  buttonText={"Login"}
                  // disabled={search || loading}
                  // onPress={() => {}}
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Box>
  );
};

export default LoginPage;
