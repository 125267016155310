import Utils from ".";

export const checkImageDimension = (file, callImageUpload) => {
  if (file.size / 1024 > 200) {
    Utils.showAlert(2, "Please Upload a image less than 200kb");
    return;
  }
  if (file && file) {
    var img = document.createElement("img");
    img.onload = function () {
      callImageUpload(this.width, this.height);
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  }
};

export const getUserTypeFromLs = () => {
  return localStorage.getItem("userType");
};
export const getUserNameFromLs = () => {
  return localStorage.getItem("username");
};
export const getUserIdFromLs = () => {
  return localStorage.getItem("id");
};
const commonFunction = {
  checkImageDimension,
  getUserTypeFromLs,
};

export default commonFunction;

export const removeEmptyValues = (data) => {
  for (var propName in data) {
    if (!data[propName] || data[propName].length === 0) {
      delete data[propName];
    } else if (typeof data[propName] === "object") {
      removeEmptyValues(data[propName]);
    }
  }
  return data;
};

export const breadCrumbTitle = (title) => {
  switch (title) {
    default:
      return title;
  }
};
export const getUpdatedNameSp = (name) => {
  switch (name) {
    case "purchase":
      return "purchases";
    case "purchase dashboard":
      return "purchases dashboard";
    case "invoice":
      return "sales invoice";
    case "order":
      return "sales order";
    case "delivery":
      return "delivery challan";
    default:
      return name;
  }
};
export const CustomBreadCrumb = (currentPath) => {
  const staticPath = ['/admins', '/claims', '/insurance'];

  if (staticPath.includes(currentPath)) {
    currentPath = '#';
  }

  return currentPath;
};
export const getUpdatedBreadcrumb = (pathnames) => {
  if (pathnames.includes("edit")) {
    var i = pathnames.indexOf("edit");
    var breadCrumbArray = pathnames.filter((x, y) => {
      return y <= i;
    });
    return breadCrumbArray;
  }
  if (pathnames.includes("add")) {
    var i = pathnames.indexOf("add");
    var breadCrumbArray = pathnames.filter((x, y) => {
      return y <= i;
    });
    return breadCrumbArray;
  }
  if (pathnames.includes("detail")) {
    var i = pathnames.indexOf("detail");
    var breadCrumbArray = pathnames.filter((x, y) => {
      return y <= i;
    });
    return breadCrumbArray;
  }

  // if (pathnames.includes('details')) {
  //   var i = pathnames.indexOf('details');
  //   var breadCrumbArray = pathnames.filter((x, y) => {
  //     return y <= i;
  //   });
  //   return breadCrumbArray;
  // }
  return pathnames;
};
