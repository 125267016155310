import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./drawer.scss";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
// import { updateProductAndServices } from "../../screens/productandServices/productServiceSlice";

export default function braveSwipeableTemporaryDrawer({
  state,
  setState,
  className,
  togalDrawrr,
  isProductDetail,
  children,
}) {
  // const dispatch = useDispatch();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (setState) {
      setState(!state);
      if (state && isProductDetail) {
        setState(false);
        // dispatch(
        //   updateProductAndServices({
        //     productServiceDetail: {},
        //   })
        // );
      }
    } else {
      togalDrawrr();
    }
  };

  const list = () => (
    <Box className={`drawer_wrap ${className}`} role="presentation">
      <Button
        variant="contained"
        className="close for-desktop"
        color="error"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <CloseIcon />
      </Button>
      <div className="drawer_wrap_content">
        <Button
          variant="contained"
          className="close for-mob"
          color="error"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <CloseIcon />
        </Button>
        {children}
      </div>
    </Box>
  );

  return (
    <React.Fragment key={"right"}>
      <SwipeableDrawer
        anchor={"right"}
        className="filter-drawer-cover"
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
