import * as React from "react";
import { useState } from "react";
import Select from "../../../component/select";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import DatePicker from "../../../component/date";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import AutoComplete from "../../../component/input/autocomplete";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

import useDebounce from "../../../hooks/debounce";
import { Formik, Form } from "formik";
import Utils from "../../../utility";
import { getStoreList } from "../../storeRelated/store/action";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import { verifyRtv, getRtvStoreStockList, discardInvoice } from "./action";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DetailGRN from "./detailGRN";
import { getProducts } from "../../productRelated/products/action";
import { getStoreRtvReport } from "../pendingAndCashoutReport/action";
import NormalButton from "../../../component/button";
import { Grid } from "@mui/material";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
    sorting: false,
  },
  {
    id: "fromStore",
    numeric: true,
    disablePadding: true,
    label: "From Store",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Product Barcode",
    sorting: false,
  },
  {
    id: "Vendor ID",
    numeric: false,
    disablePadding: true,
    label: "Brand",
  },
  {
    id: "Vendor",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
    sorting: false,
  },
  {
    id: "Done By",
    numeric: false,
    disablePadding: true,
    label: "RTV Done By",
    sorting: false,
  },

  {
    id: "Invoice Number",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  // {
  //   id: "status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "status",
  //   sorting: false,
  // },
  {
    id: "Store",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
    isWarehouseRoutes,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : isWarehouseRoutes === "/warehouse-rtvstocks" &&
              headCell.id === "fromStore" ? (
              "From warehouse"
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    storeId,
    storeList,
    warehouseList,
    handleStoreSelect,
    warehouseId,
    handleWarehouseSelect,
    isWarehouseRoutes,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        RTV Stocks
      </Typography>
      {/* <Stack sx={{ width: "250px", marginLeft: 1 }}>
        {isWarehouseRoutes === "/warehouse-rtvstocks" ? (
          <Select
            handleChange={handleWarehouseSelect}
            value={warehouseId}
            label={"Select Warehouse"}
            option={warehouseList?.map((item) => (
              <MenuItem key={item.id} value={item?.id}>
                {item?.warehouseNickname}
              </MenuItem>
            ))}
            name="manager"
          />
        ) : (
          <Select
            handleChange={handleStoreSelect}
            value={storeId}
            label={"Select Stores"}
            option={storeList?.map((item) => (
              <MenuItem key={item.id} value={item?.id}>
                {item?.storename}
              </MenuItem>
            ))}
            name="Stores"
          />
        )}
      </Stack> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const initialValues = {
    storeId: null,
    toDate: moment(),
    fromDate: moment(),
  };

  const [warehouseId, setWarehouseId] = React.useState(null);
  const [isEdit, setIsEdit] = useState(null);
  let isWarehouseRoutes = useLocation().pathname;
  const { rtvStoreStock, order, sortField, offset, take, search, count } =
    useSelector((state) => state.rtvStoreStockReducer);

  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    if (storeId) dispatch(getRtvStoreStockList(storeId ? storeId : ""));
  }, [debouncedSearch]);

  const [storeId, setStoreId] = useState(null);
  // React.useEffect(() => {
  //   dispatch(getRtvStoreStockList());

  //   // dispatch(getWarehouseList());
  //   // dispatch(getProducts());
  //   // dispatch(getStoreList());
  // }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    // dispatch(getAllGRNList());
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { offset: newPage + 1 },
    });
    dispatch(getRtvStoreStockList(storeId));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getRtvStoreStockList(storeId));
  };

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { search: searchedVal },
    });
  };

  // const { storeList } = useSelector((state) => state.storeReducer);
  // const { warehouseList } = useSelector((state) => state.warehouseReducer);
  const {
    warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    // productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const handleWarehouseSelect = (e) => {
    setWarehouseId(e.target.value);
    dispatch(getRtvStoreStockList(e.target.value, "warehouse"));
  };
  const handleStoreSelect = (e) => {
    // setStoreId(e.target.value);
    dispatch(getRtvStoreStockList(e)); // dispatch(getStoreStockList());
  };
  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* {!permissions || permissions["storeRTV"]?.add ? (
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => setOpen(true)}
                  >
                    Add RTV
                  </Button>
                ) : (
                  <></>
                )} */}
          <div>
            <Formik
              initialValues={initialValues}
              // validationSchema={Schema.SKUTimelineSchema}
              onSubmit={(values, { setSubmitting }) => {
                // setInitialValue(values);
                dispatch(getStoreRtvReport(values));
                // dispatch(getSKUTimeline(values));
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box sx={{ display: "flex" }}>
                    {/* {console.log(values, errors, "shashank")} */}
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ marginRight: 20 }}
                    >
                      <AutoComplete
                        freeSolo={false}
                        className="w-200"
                        // onBlur={handleBlur}
                        values={values.storeId}
                        onChange={(event, value) => {
                          if (value !== null) {
                            const index = storeListForDropdown.findIndex(
                              (x) => x.id === value.id
                            );

                            setFieldValue("storeId", value?.id);
                            handleStoreSelect(value?.id);
                          }
                        }}
                        helperText={touched.storeId && errors.storeId}
                        error={Boolean(touched.storeId && errors.storeId)}
                        name={"storeId"}
                        label={"Store Name"}
                        touched={touched.storeId}
                        options={storeListForDropdown}
                        getOptionLabel={(option) => option?.storename}
                        // isRequired={true}
                        getOptionSelected={(option, value) => {
                          return `${option.id}` === `${value.id}`;
                        }}
                        defaultValue={() => {
                          const index = storeListForDropdown?.findIndex(
                            (x) => x?.id == values.storeId
                          );
                          return storeListForDropdown[index];
                        }}
                      />
                    </Stack>
                    <Stack style={{ width: 200 }}>
                      <DatePicker
                        value={values.fromDate}
                        name="fromDate"
                        handleChange={(newVal) =>
                          setFieldValue("fromDate", newVal)
                        }
                        label="From Date"
                        error={errors.fromDate}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2} style={{ width: 200 }}>
                      <DatePicker
                        value={values.toDate}
                        name="toDate"
                        handleChange={(newVal) =>
                          setFieldValue("toDate", newVal)
                        }
                        label="To Date"
                        error={errors.toDate}
                      />
                    </Stack>

                    {/* <Button
                      variant="outlined"
                      spacing={2}
                      sx={{ mr: 2 }}
                      type="submit"
                      disabled={!values?.storeId || !rtvStoreStock.length}
                    >
                      Get Report
                    </Button> */}
                    <Grid item xs={8} sm={6} lg={3}>
                      <NormalButton
                        className="btn-purple fend h-100"
                        buttonText="Generate Report"
                        // onPress={() => {}}
                        type="submit"
                      />
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
          {/* <Box className="search_bar w-200 ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              // clearSearch={handleSearchClear}
            />
          </Box> */}
        </div>
        <EnhancedTableToolbar
          storeList={storeListForDropdown}
          warehouseList={warehouseListForDropdown}
          warehouseId={warehouseId}
          storeId={storeId}
          isWarehouseRoutes={isWarehouseRoutes}
          // handleWarehouseSelect={handleWarehouseSelect}
          // handleStoreSelect={handleStoreSelect}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              isWarehouseRoutes={isWarehouseRoutes}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rtvStoreStock.length &&
                rtvStoreStock.map((row, index) => {
                  if (
                    isWarehouseRoutes === "/warehouse-rtvstocks" &&
                    row?.rtvStocks?.store?.id
                  ) {
                    return false;
                  } else {
                    if (
                      isWarehouseRoutes === "/rtvstocks" &&
                      row?.rtvStocks?.warehouse?.id
                    ) {
                      return false;
                    }
                  }

                  return (
                    <TableRow hover key={row?.id}>
                      <TableCell
                        component="th"
                        id={index}
                        scope="row"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        {row?.id}
                      </TableCell>
                      <TableCell align="left">
                        {row?.rtvStocks?.store?.storename}
                      </TableCell>
                      <TableCell align="left">{row?.product?.name}</TableCell>
                      <TableCell align="left">
                        {row?.product?.barcode}
                      </TableCell>
                      <TableCell align="left">-</TableCell>
                      <TableCell align="left">{row?.quantity}</TableCell>
                      <TableCell align="left">
                        {row?.rtvStocks?.doneBy?.name}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row?.rtvStocks?.addDate).format("DD-MM-YYYY")}
                      </TableCell>
                      {/* <TableCell align="left">
                          {row?.approvedStatus}
                        </TableCell> */}

                      <TableCell align="left">
                        {/* {!permissions && ( */}
                        <>
                          {/* {row?.approvedStatus !== "approved" && (
                                <VerifiedIcon
                                  onClick={() =>
                                    dispatch(
                                      verifyRtv(row?.id, storeId ? storeId : "")
                                    )
                                  }
                                />
                              )} */}
                          <VisibilityIcon
                            onClick={() => {
                              dispatch(discardInvoice(row?.rtvStocks?.id));
                            }}
                          />
                        </>
                        {/* )} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={"Add RTV"}
        children={
          <DetailGRN
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            getUpdatedListing={() => dispatch(getRtvStoreStockList(storeId))}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={1000}
      />
    </>
  );
}
