import { Fragment } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { CloseIMG } from '../../utility'
import './searchinput.scss'
import '../input/input.scss'

export default function SearchInput({
  handleSearch,
  value,
  clearSearch,
  placeholder,
  className,
  id,
  name,
}) {
  return (
    <TextField
      value={value}
      size="small"
      variant="outlined"
      name={name}
      id={id}
      autoComplete="off"
      className={`searchInput ${className}`}
      placeholder={placeholder ? placeholder : 'Search'}
      onChange={handleSearch}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value !== '' ? (
              <img
                src={CloseIMG}
                alt="CLose"
                className={'closeIcon'}
                onClick={clearSearch}
              />
            ) : (
              <Fragment />
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}
