import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";

// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { getDailyCSV, updateStoreStock } from "./action";
import DatePicker from "../../../component/date";
import { Box } from "@mui/material";
import { getPOById } from "../po/action";
import Select from "../../../component/select";
import Utils from "../../../utility";
import UploadImage from "../../../component/uploadImage";
import moment from "moment";

const DailyStcokReport = ({ setOpen, openEditSS }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);

  const initialValues = {
    storeId: "",
    searchDate: moment(new Date()).subtract(1, "days"),
  };
  const { storeListForDropdown: storeList } = useSelector(
    (state) => state.commonReducer
  );
  // console.log(openEditSS, "openEditSS");
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={Schema.storeStockSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (!values?.storeId) {
            Utils.showAlert(2, "Please select store");
            return;
          }
          dispatch(getDailyCSV(values, setOpen));
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
              <AutoComplete
                freeSolo={false}
                // onBlur={handleBlur}
                values={values.store}
                onChange={(event, value) => {
                  if (value !== null) {
                    setFieldValue("storeId", value?.id);
                    // setFieldValue("storeName", value?.storename);
                  }
                }}
                helperText={touched.storeId && errors.storeId}
                error={Boolean(touched.storeId && errors.storeId)}
                name={"storeId"}
                label={"Store Name"}
                touched={touched.storeId}
                options={storeList || []}
                getOptionLabel={(option) => option?.storename}
                // isRequired={true}
                getOptionSelected={(option, value) => {
                  return `${option?.id}` === `${value?.id}`;
                }}
                // defaultValue={() => {
                //   if (storeList?.length) {
                //
                //     const index = storeList?.findIndex(
                //       (x) => x?.id == values.storeId
                //     );
                //     return storeList[index];
                //   }
                // }}
              />
              <DatePicker
                value={values.searchDate}
                name="searchDate"
                handleChange={(newVal) => setFieldValue("searchDate", newVal)}
                label="Select Date"
                error={errors.searchDate}
                maxDate={new Date(moment(new Date()).subtract(1, "days"))}
              />
            </Box>

            <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
              <Button variant="outlined" type="submit">
                Get report
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
    //  </Authlayout>
  );
};

export default DailyStcokReport;
