import * as React from "react";
import { useState } from "react";
import useDebounce from "../../../hooks/debounce";

import Utils from "../../../utility";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import { deletePropertyOwner, getPropertyOwner } from "./action";
import { getAllUser } from "../users/action";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddUser from "./addPropertyOwner";
import { getStoreList } from "../../storeRelated/store/action";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortDisable: false,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
    sortDisable: true,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sortDisable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell.sortDisable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "DESC"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Property Owner
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [isEdit, setIsEdit] = useState(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const {
    propertyOwnerList,
    products,
    order,
    sortField,
    offset,
    take,
    search,
    count,
  } = useSelector((state) => state.propertyOwnerReducer);
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    // dispatch(getAllUser(50));
    // dispatch(getStoreList(50));
    return () => {
      dispatch({ type: Utils.ActionName.RESET_PROPERTY_OWNER });
    };
  }, []);
  React.useEffect(() => {
    dispatch(getPropertyOwner());
  }, [debouncedSearch]);
  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";

    dispatch({
      type: Utils.ActionName.PROPERTY_OWNER,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getPropertyOwner());
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.PROPERTY_OWNER,
      payload: { offset: newPage + 1 },
    });
    dispatch(getPropertyOwner());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.PROPERTY_OWNER,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getPropertyOwner());
  };

  const handleSearch = (e) => {
    dispatch({
      type: Utils.ActionName.PROPERTY_OWNER,
      payload: { search: e.target.value, take: take, offset: 1 },
    });
    // dispatch(getPropertyOwner());
  };
  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {!permissions || permissions["propertyOwner"]?.add ? (
            <AddButton
              text="Add Property Owner"
              className="btn-purple m-l-10"
              onPress={() => setOpen(true)}
            />
          ) : (
            <></>
          )}
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              // clearSearch={handleSearchClear}
            />
          </Box>
        </div>
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              numSelected={0}
              onSelectAllClick={function (event) {
                throw new Error("Function not implemented.");
              }}
            />
            <TableBody>
              {propertyOwnerList.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell component="th" id={index} scope="row">
                      {row?.user?.id}
                    </TableCell>
                    <TableCell align="left">{row?.user?.userName}</TableCell>
                    <TableCell align="left">{row?.user?.address}</TableCell>
                    <TableCell align="left">
                      <>
                        {" "}
                        {!permissions || permissions["propertyOwner"]?.edit ? (
                          <EditIcon
                            onClick={() => {
                              setIsEdit(
                                propertyOwnerList.filter(
                                  (item) => item.id === row.id
                                )
                              );
                              setOpen(true);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {!permissions ||
                        permissions["propertyOwner"]?.delete ? (
                          <DeleteIcon
                            onClick={() => {
                              setDeleteConfirmation(row.id);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          className="pagination-cover Dflex fl-end"
          sx={{ fontSize: "12rem" }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Modal
        header={`${isEdit ? "Edit" : "Add"} Property Owner`}
        children={
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      />
      <DeleteConfirmationPopup
        title="Property Owner"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deletePropertyOwner(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
    </>
  );
}
