import * as React from "react";
import { useState } from "react";

import Utils from "../../../utility";
import Select from "../../../component/select";
import MenuItem from "@mui/material/MenuItem";
import DiscordTable from "../../../component/discordStocks";
import useDebounce from "../../../hooks/debounce";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Reports from "./reports";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { getRtvStoreStockList } from "../rtvStocks/action";
import { Button } from "@mui/material";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

// import DetailGRN from "./detailGRN";
// import Modal from "../../component/modal"
// import AddUser from "./addstore";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
    sorting: false,
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "From Store",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: true,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Product Barcode",
    sorting: false,
  },
  {
    id: "Vendor ID",
    numeric: false,
    disablePadding: true,
    label: "Brand",
  },
  {
    id: "Vendor",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
    sorting: false,
  },
  {
    id: "Done By",
    numeric: false,
    disablePadding: true,
    label: "RTV Done By",
    sorting: false,
  },
  {
    id: "Excess/Short",
    numeric: false,
    disablePadding: true,
    label: "Excess/Short",
    sorting: false,
  },
  {
    id: "Invoice Number",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  {
    id: "Store",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, storeId, setStoreId } = props;
  // const [storeId, setStoreId] = useState(null);
  const dispatch = useDispatch();
  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    // productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Discard Stocks
      </Typography>
      <Stack sx={{ width: 350 }}>
        <Select
          handleChange={(e) => {
            setStoreId(e.target.value);
            dispatch(getRtvStoreStockList(e.target.value, true));
          }}
          value={storeId}
          label={"Select Store"}
          option={storeListForDropdown?.map((item) => (
            <MenuItem key={item.id} value={item?.id}>
              {item?.storename}
            </MenuItem>
          ))}
          name="manager"
        />
      </Stack>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState(null);
  const { offset, take, search, count } = useSelector(
    (state) => state.rtvStoreStockReducer
  );
  const [open, setOpen] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    if (search) dispatch(getRtvStoreStockList(storeId, true));
  }, [debouncedSearch]);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { offset: newPage + 1 },
    });
    dispatch(getRtvStoreStockList(storeId, true));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getRtvStoreStockList(storeId, true));
  };

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { search: searchedVal },
    });
    if (!searchedVal) dispatch(getRtvStoreStockList(storeId, true));
  };

  // React.useEffect(() => {
  //   setRows(propertyOwnerList);
  // }, [propertyOwnerList]);
  // const {
  //   warehouseListForDropdown,
  //   storeListForDropdown,
  //   // brandOwnerListForDropdown,
  //   productsDropDownList,
  //   // brandOwnerListForDropdown,
  // } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          <AddButton
            text=" Generate Report"
            className="btn-purple m-l-10"
            onPress={() => setOpen(true)}
          />
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              // clearSearch={handleSearchClear}
            />
          </Box>
        </div>

        <EnhancedTableToolbar storeId={storeId} setStoreId={setStoreId} />
        <DiscordTable isStoreDiscard selectId={storeId} />

        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={`Generate discard report`}
        children={<Reports storeId={storeId} open={open} setOpen={setOpen} />}
        handleClose={() => {
          setOpen(false);
          // setIsEdit(null);
        }}
        open={open}
        // minWidth={"85%"}
      />
    </>
  );
}
