import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Avatar from "@mui/material/Avatar";

import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Logo from "../../assets/images/New Logo .jpg";
import "./header.scss";
// import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {
  Box,
  Button,
  ClickAwayListener,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import AddProductDrawer from "../drawer";
// import {
//   clearNotification,
//   getNotificationList,
//   notificationCountValue,
// } from './action';
// import { updatenotificationList } from "./notificationSlice";

// import {}
// import { getLastNameFromLS, getNamefromSession } from "../../utils";
// import { logout } from '../../screens/onboarding/login/action';
// import IconLabelButtons from "../buttons/buttonWithIcon";
// import Notiification from "./notiification";
// import useDebounce from "../../hooks/use.debounce";
// import NormalButton from "../buttons/normalButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  getUserNameFromLs,
  getUserTypeFromLs,
} from "../../utility/commonFunction";
// import { updatedashboard } from "../../screens/dashborad/dashboardSlice";

export default function Header({ open, setOpen, clname }) {
  const Navigate = useNavigate();
  const [openState, setNotificationState] = React.useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const drpopen = Boolean(anchorEl);
  const [notopen, setOpennot] = React.useState(false);
  // const { notificationList, notificationCount } = useSelector(
  //   (state) => state.notificationSlice
  // );

  const location = useLocation();

  // useEffect(() => {
  //   if ((permissions && permissions["notification"]?.view) || !permissions) {
  //     dispatch(notificationCountValue());
  //   }
  // }, [location]);
  // const { organizationDeatil } = useSelector(
  //   (state: any) => state.settingSlice
  // );
  //Conditions for overflow hidden in body
  // useEffect(() => {
  //   if (notopen) {
  //     document.body.classList.add("hidden_noti");
  //   }
  //   if (!notopen) {
  //     document.body.classList.remove("hidden_noti");
  //   }
  // }, [notopen]);

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
    Navigate("/my-profile");
    // document.body.classList.add('inherit-scroller');
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.body.classList.remove("inherit-scroller");
  };

  const handlemenuIcon = () => {
    // dispatch(updatedashboard({ reloadDashboard: !reloadDashboard }));

    if (setOpen) setOpen(!open);
  };

  const handlemenuNoti = () => {
    if (!notopen) {
      // dispatch(getNotificationList());
    }
    setOpennot((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpennot(false);
  };

  const handleLogout = () => {
    // dispatch(logout(Navigate));
  };

  const handleClearNotification = () => {
    // dispatch(clearNotification());
  };
  // const { permissions } = useSelector((state: any) => state.commonSlice);

  return (
    <header className={clname}>
      <div className="lt">
        <Box className="lg_wrap">
          <Link to="/dashboard">
            <img
              src={Logo}
              // src={"https://thenewshop.in/header-logo.png"}
              alt="The new Shop"
              className="lgb"
            />
          </Link>
          <Box className="ar_nv" onClick={handlemenuIcon}>
            {/* <img src={MENUARROW} alt="" /> */}
            <KeyboardArrowLeftOutlinedIcon />
          </Box>
        </Box>
      </div>
      <div className="rt">
        <div className="header_user_del">
          {/* {localStorage.getItem('lastLogin') ? (
            <div
              className="last-login"
              style={{ fontSize: 10, marginRight: 10 }}
            >
              Last Login:{' '}
              {moment(localStorage.getItem('lastLogin')).format('DD-MM-YYYY')}
              <br />
              Ip address: {localStorage.getItem('ipAddress')}
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )} */}
          {/* {(permissions && permissions["notification"]?.view) ||
          !permissions ? ( */}

          {/* ) : (
            <></>
          )} */}
          <Button
            className="user_del"
            aria-controls={drpopen ? "acSetting-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={drpopen ? "true" : undefined}
            onClick={handleClick}
          >
            {/* <AccountCircleOutlinedIcon sx={{ fontSize: 40 }} /> */}
            <Avatar sx={{ bgcolor: "black" , color:"#ffde59"}}>
              {getUserTypeFromLs()}
            </Avatar>

            <Typography
              variant="subtitle1"
              className="semi-bold"
              sx={{ mx: 1 }}
            >
              {getUserNameFromLs()}
            </Typography>
            {/* <ArrowDropDownOutlinedIcon /> */}
          </Button>
          <Menu
            id="acSetting-menu"
            anchorEl={anchorEl}
            open={drpopen}
            onClose={handleClose}
            className="setting-menu-popper"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: -1,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* {((permissions && permissions["settings"]?.view) ||
              !permissions) && ( */}
            {/* <MenuItem
              onClick={() => {
                handleClose();
                // Navigate(routesConstant.routeConstants.settings);
              }}
            >
              <Typography variant="subtitle2" className="m-l-10">
                Settings
              </Typography>
            </MenuItem> */}
            {/* )} */}
            {/* <img src={SETTINGIMG} alt="" />{' '} */}

            {/* {!permissions && ( */}
            {/* <MenuItem
              onClick={() => {
                handleClose();
                // Navigate(routesConstant.routeConstants.userManagement);
              }}
            >
              <Typography variant="subtitle2" className="m-l-10">
                Team Mangement
              </Typography>
            </MenuItem> */}
            {/* <img src={TEAMIMG} alt="" />{' '} */}

            <MenuItem onClick={handleLogout}>
              {/* <img src={LOGOUBLUEIMG} alt="" />{' '} */}
              <Typography variant="subtitle2" className="m-l-10">
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
}
