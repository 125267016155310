import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
import { CSVLink } from "react-csv";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddButton from "../../../component/button/buttonWithIcon";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import moment from "moment";

const headers = [
  { label: "Product ID", key: "ProductID" },
  { label: "Product Name", key: "ProductName" },
  { label: "Batch", key: "Batch" },
  { label: "Invoice Quantity", key: "InvoiceQuantity" },
  { label: "Recd. Quantity", key: "RecdQuantity" },
  { label: "MRP", key: "MRP" },
  { label: "Total Amount", key: "TotalAmount" },
  { label: "Vendor Name", key: "venderName" },
  { label: "Po", key: "po" },
  { label: "Invoice", key: "Invoice" },
  { label: "GRN by", key: "GRNBy" },
  { label: "Vehicle", key: "vehicle" },
];

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Product ID",
    sorting: false,
  },

  {
    id: "barcode",
    numeric: false,
    disablePadding: true,
    label: "Barcode ",
    sorting: false,
  },
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: false,
  },
  {
    id: "Recd.Quantity",
    numeric: false,
    disablePadding: true,
    label: "Recd. Quantity",
    sorting: false,
  },
  {
    id: "storeType",
    numeric: false,
    disablePadding: true,
    label: "Selling Price",
    sorting: false,
  },

  {
    id: "storeType",
    numeric: false,
    disablePadding: true,
    label: "Cost Price",
    sorting: false,
  },

  {
    id: "Total Amount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const { GRNDetail } = useSelector((state) => state.GRNReducer);
  const titleAndItsDetail = (name, desc) => (
    <Typography
      sx={{ flex: "1 1 100%" }}
      // variant="h6"
      id="tableTitle"
      component="div"
    >
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="subtitle2" className="color-label m-b-5">
          {name}
        </Typography>
        <Typography variant="body2">{desc}</Typography>
      </Grid>
    </Typography>
  );
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // display: "flex",
        // flexWrap: "wrap",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {titleAndItsDetail("Vendor Name", GRNDetail?.vendor?.user?.name)}
      {titleAndItsDetail(
        "PO",
        GRNDetail?.purchaseOrder?.poNumber
          ? GRNDetail?.purchaseOrder?.poNumber
          : "-"
      )}
      {titleAndItsDetail("Invoice No", GRNDetail?.invoiceNumber)}
      {titleAndItsDetail("Grn by", GRNDetail?.createdBy?.name || "N/A")}
      {titleAndItsDetail("Vehicle", GRNDetail?.vehicleNo || "N/A")}
      {titleAndItsDetail("Grn Id", GRNDetail?.id || "N/A")}
      {titleAndItsDetail(
        "GRN Date",
        moment(GRNDetail?.createdAt).format("DD-MM-YYYY HH:mm:ss") || "N/A"
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ handlePrint }) {
  const dispatch = useDispatch();
  const [cvcData, setCvcData] = useState([]);
  // const [orderBy, setOrderBy] = React.useState("calories");
  const [selected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [open, setOpen] = useState(false);
  // const [rows, setRows] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.STORE,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    // dispatch(getStoreList());
  };

  // const handleChangePage = (event, newPage) => {
  //   dispatch({
  //     type: Utils.ActionName.STORE,
  //     payload: { offset: newPage + 1 },
  //   });
  //   // dispatch(getStoreList());
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   // setRowsPerPage(parseInt(event.target.value, 10));
  //   dispatch({
  //     type: Utils.ActionName.STORE,
  //     payload: { take: event.target.value, offset: 1 },
  //   });
  //   // dispatch(getStoreList());
  // };

  const { GRNDetail, order, sortField } = useSelector(
    (state) => state.GRNReducer
  );
  React.useEffect(() => {
    if (GRNDetail?.grnProducts?.length) {
      const GRNCVC = GRNDetail?.grnProducts.map((item) => {
        return {
          ProductID: item?.product?.id,
          ProductName: item?.product?.name,
          Batch: item.batch,
          InvoiceQuantity: item.invoiceQuantity,
          RecdQuantity: item.recievedQuantity,
          MRP: item.printedMrp,
          TotalAmount: item.recievedQuantity * item.printedMrp,
          Vendor: GRNDetail?.vender?.alias ? GRNDetail?.vender?.alias : "",
          Po: GRNDetail?.PO ? GRNDetail?.PO : "",
          Invoice: GRNDetail?.invoiceNumber ? GRNDetail?.invoiceNumber : "",
          GRN: "",
          Vehicle: "",
        };
      });
      setCvcData(GRNCVC);
    }
  }, [GRNDetail]);

  return (
    <>
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              // onClick={() => setOpen(true)}
              className="btn buttonWithIcon"
            >
              <CSVLink data={cvcData} headers={headers} filename={"store-grn"}>
                Export
              </CSVLink>
            </Button>
            <AddButton
              text="Print Grn"
              className="btn-purple m-l-10"
              onPress={() => handlePrint()}
            />
          </Stack>
        </div>
        <TableContainer>
          <hr className="m-t-20 m-b-20" />

          <EnhancedTableToolbar numSelected={selected.length} />
          <hr className="m-t-20 m-b-20" />

          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              // rowCount={storeList.length}
            />

            <TableBody>
              {GRNDetail?.grnProducts?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={row?.product?.id}
                    // selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={index}
                      scope="row"
                      // padding="none"
                    >
                      {row?.product?.id}
                    </TableCell>
                    <TableCell align="left">{row?.product?.barcode}</TableCell>

                    <TableCell align="left">{row?.product?.name}</TableCell>

                    <TableCell align="left">{row?.recievedQuantity}</TableCell>
                    <TableCell align="left">{row?.sellingPrice}</TableCell>

                    <TableCell align="left">{row?.productCostPrice}</TableCell>
                    <TableCell align="left">
                      {row?.productCostPrice * row?.recievedQuantity}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
