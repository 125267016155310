import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
import MenuItem from "@mui/material/MenuItem";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import moment from "moment";
import { Formik, Form } from "formik";
import Schema from "../../../schema";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import useDebounce from "../../../hooks/debounce";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

import {
  // getWarehouseStockListById,
  getPOById,
  getPOkList,
} from "./action";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import Modal from "../../component/modal"
import AddUser from "./addPo";
import Select from "../../../component/select";
import { getProducts } from "../../productRelated/products/action";
import {
  getBiller,
  getOprationManagerList,
  getStoreList,
} from "../store/action";
import { getBrandOwnerList } from "../../userAccount/brandOwner.js/action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PODetail from "./detailPO";
// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "PO Id",
  },
  {
    id: "warehouseNickname",
    numeric: false,
    disablePadding: true,
    label: "GRN Id",
  },

  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  // {
  //   id: "bin",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Bin",
  // },
  {
    id: "brand",
    numeric: false,
    disablePadding: true,
    label: "Vender Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Vender Name",
    sorting: true,
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Made By",
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Store ID",
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Store Name",
    sorting: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sorting: true,
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Total Value",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        PO
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [storeId, setStoreId] = React.useState(null);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [openPO, setOpenPO] = useState(false);

  const [rows, setRows] = useState([]);
  const [originalRow, setOriginalRow] = useState([]);
  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const { POList, count, order, sortField, offset, take, search } = useSelector(
    (state) => state.POReducer
  );
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    dispatch(getPOkList());
  }, [debouncedSearch]);

  React.useEffect(() => {
    // dispatch(getWarehouseList());
    // dispatch(getProducts());
    dispatch(getStoreList());
    dispatch(getBrandOwnerList());
    // dispatch(getPOById("202281300000", 1));
    // dispatch(getPropertyOwner());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.PO,
      payload: { offset: newPage + 1 },
    });
    dispatch(getPOkList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.PO,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getPOkList());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.PO,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getPOkList());
    // setOrderBy(property);
  };
  // Avoid a layout jump when reaching the last page with empty rows.

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.PO,
      payload: { search: searchedVal, take: take, offset: 1 },
    });
  };
  const handleWarehouseSelect = (e) => {
    setStoreId(e.target.value);
    dispatch(getPOkList(e.target.value));
  };

  // const { brandOwnerList } = useSelector((state) => state.brandOwnerReducer);

  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);
  const initialValues = {
    storeId: null,
    storeName: "",
    productName: "",
    endDate: moment(),
    startDate: moment(),
    productId: null,
  };
  // const { products } = useSelector((state) => state.productsReducer);
  const {
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // warehouseListForDropdown,
  } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* <Tooltip title="Please select store First"> */}

          <AddButton
            text="Create PO"
            className="btn-purple m-l-10"
            onPress={() => {
              setOpen(true);
            }}
          />
          {/* </Tooltip> */}
          <Stack sx={{ width: "200px", marginLeft: 1 }}>
            <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
              <Formik
                // enableReinitialize = {}
                initialValues={initialValues}
                // validationSchema={Schema.SKUTimelineSchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch({
                    type: Utils.ActionName.PO,
                    payload: values,
                  });
                  dispatch(getPOkList(values));
                  // setInitialValue(values);
                  // dispatch(getSKUTimeline(values));
                  // if (isEdit?.length) {
                  //   dispatch(updateUser(values, setOpen, isEdit[0].id, setIsEdit));
                  // } else {
                  //
                  // }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box sx={{ display: "flex" }}>
                      <Stack direction="row" spacing={2} style={{ width: 200 }}>
                        <AutoComplete
                          freeSolo={false}
                          // onBlur={handleBlur}
                          values={values.storeId}
                          onChange={(event, value) => {
                            if (value !== null) {
                              const index = storeListForDropdown.findIndex(
                                (x) => x.id === value.id
                              );

                              setFieldValue("storeId", value?.id);
                              setFieldValue("storeName", value?.storename);
                              dispatch({
                                type: Utils.ActionName.SKU_TIMELINE,
                                payload: { skuData: [] },
                              });
                            }
                          }}
                          helperText={touched.storeId && errors.storeId}
                          error={Boolean(touched.storeId && errors.storeId)}
                          name={"storeId"}
                          label={"Store Name"}
                          touched={touched.storeId}
                          options={storeListForDropdown}
                          getOptionLabel={(option) => option?.storename}
                          // isRequired={true}
                          getOptionSelected={(option, value) => {
                            return `${option.id}` === `${value.id}`;
                          }}
                          defaultValue={() => {
                            const index = storeListForDropdown?.findIndex(
                              (x) => x?.id == values.storeId
                            );
                            return storeListForDropdown[index];
                          }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} style={{ width: 200 }}>
                        <AutoComplete
                          freeSolo={false}
                          // onBlur={handleBlur}
                          values={values.vendor}
                          onChange={(event, value) => {
                            if (value !== null) {
                              const index = brandOwnerListForDropdown.findIndex(
                                (x) => x.id === value.id
                              );
                              setFieldValue("vender", value?.id);
                              // setFieldValue("productName", value?.name);
                              // dispatch({
                              //   type: Utils.ActionName.SKU_TIMELINE,
                              //   payload: { skuData: [] },
                              // });
                            }
                          }}
                          helperText={touched.vendor && errors.vendor}
                          error={Boolean(touched.vendor && errors.vendor)}
                          name={"vendor"}
                          label={"Vendor"}
                          touched={touched.vendor}
                          options={brandOwnerListForDropdown}
                          getOptionLabel={(option) => option?.alias}
                          // isRequired={true}
                          getOptionSelected={(option, value) => {
                            return `${option.id}` === `${value.id}`;
                          }}
                          defaultValue={() => {
                            const index = brandOwnerListForDropdown?.findIndex(
                              (x) => x?.id == values?.productId
                            );
                            return brandOwnerListForDropdown[index];
                          }}
                        />
                      </Stack>

                      <Stack style={{ width: 200 }}>
                        <DatePicker
                          value={values.startDate}
                          name="startDate"
                          handleChange={(newVal) =>
                            setFieldValue("startDate", newVal)
                          }
                          label="From Date"
                          error={errors.startDate}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} style={{ width: 200 }}>
                        <DatePicker
                          value={values.endDate}
                          name="endDate"
                          handleChange={(newVal) =>
                            setFieldValue("endDate", newVal)
                          }
                          label="To Date"
                          error={errors.endDate}
                        />
                      </Stack>

                      <Button
                        variant="outlined"
                        spacing={2}
                        sx={{ mr: 2 }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Stack>
        </div>
        <Box className="search_bar ">
          <SearchInput
            value={search}
            placeholder="Search here..."
            handleSearch={handleSearch}
            // clearSearch={handleSearchClear}
          />
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              // rowCount={warehouseStockLi st.length}
            />
            {/* {console.log(storeStockList, "storeStockList")} */}
            <TableBody>
              {POList &&
                POList?.length &&
                POList?.map((row, index) => {
                  return (
                    <TableRow hover key={row?.id}>
                      <TableCell component="th" id={index} scope="row">
                        {row?.poNumber || "N/A"}
                      </TableCell>
                      <TableCell align="left">{row?.grnId || "N/A"}</TableCell>
                      <TableCell align="left">{row?.date || "N/A"}</TableCell>
                      <TableCell align="left">
                        {row?.vendorId || "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.vendorName || "N/A"}
                      </TableCell>
                      <TableCell align="left">{row?.madeBy || "N/A"}</TableCell>
                      <TableCell align="left">{row?.store}</TableCell>
                      <TableCell align="left">{row?.storeName}</TableCell>
                      <TableCell align="left">{row?.type}</TableCell>
                      <TableCell align="left">{row?.status}</TableCell>
                      <TableCell align="left">{row?.totalValue}</TableCell>
                      <TableCell align="left">
                        <VisibilityIcon
                          onClick={() => {
                            dispatch(getPOById(row.poNumber, row?.store));
                            setOpenPO(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={`${isEdit ? "Edit" : "Add"} PO`}
        children={
          <AddUser
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            storeId={storeId}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={"80%"}
      />
      <Modal
        header={"PO Detail"}
        children={
          <PODetail
            setOpenPO={setOpenPO}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        }
        handleClose={() => {
          setOpenPO(false);
          // setIsEdit(null);
        }}
        open={openPO}
        minWidth={1000}
      />
      {/* <DeleteConfirmationPopup
        title="Store Stock"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          // dispatch(deleteStoreStock(deleteConfirmation, storeId));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      /> */}
    </>
  );
}
