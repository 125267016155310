import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function ColorButtons({
  text,
  onPress,
  color,
  disabled = false,
}) {
  return (
    <Button
      variant="outlined"
      color={color ? color : "error"}
      sx={{ width: "100%" }}
      onClick={onPress}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
