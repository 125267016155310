import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { getStoreList } from "../store/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import NormalButton from "../../../component/button";

import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Schema from "../../../schema";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
// import { getSKUTimeline, getSKUTimelineAll } from "./action";
import { Box } from "@mui/material";

import Utils from "../../../utility";
import {
  getCashoutPendingReport,
  getCashoutReport,
  getReturnReport,
  getStoreGrnReport,
  getStoreRtvReport,
} from "./action";
import { useLocation } from "react-router-dom";
import { getUserTypeFromLs } from "../../../utility/commonFunction";
import { getStoreDiscardRtvReport } from "../discordStoreStock/action";
import { generatReport } from "../../invoiceAndBilling/order/action";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sortDisable: false,
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Logs",
    sortDisable: true,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Previous Quantity",
    sortDisable: true,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Currnet Quantity Quantity",
    sortDisable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sortDisable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const [csvData, setCsvData] = useState([]);
  const location = useLocation();
  // console.log(location, "location");
  const [cvcData, setCvcData] = useState([]);
  const { permissions } = useSelector((state) => state.commonReducer);

  const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();
  const initialValues = {
    storeId: null,
    toDate: moment(),
    fromDate: moment(),
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Created At", key: "createdAt" },
    { label: "Time", key: "time" },
    { label: "Cash Pending", key: "cashPending" },

    { label: "reason", key: "reason" },
    { label: "doneBy", key: "doneBy" },
    { label: "Store Name", key: "storeName" },
    // { label: "Cash Pending", key: "cashPending" },
  ];
  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);
  // const {  } = useSelector((state) => state.SkuTimelineReducer);
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const { cashPendingReport, cashoutReport } = useSelector(
    (state) => state.reportReducer
  );

  React.useEffect(() => {
    return () => {
      dispatch({
        type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
        payload: {
          cashoutReport: [],
          // count: respData.data[1],
        },
      });
    };
  }, [location.pathname]);
  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const csvDownload = React.useRef(null);
  React.useEffect(() => {
    if (cashPendingReport?.length) {
      const GRNCVC = cashPendingReport.map((item) => {
        return {
          cashPending: item?.cashPending,
          createdAt: item?.createdAt,
          id: item?.id,
        };
      });
      setCvcData(GRNCVC);
    }
  }, [cashPendingReport]);

  const csvDownloadCashout = React.useRef(null);
  React.useEffect(() => {
    if (cashoutReport?.length) {
      const GRNCVC = cashoutReport?.map((item) => {
        // console.log(moment(item?.addDate).format("HH:mm"),"shashank")
        // then use this code
        var serverTime = item?.addDate?.replace("T", " ");
        let today = serverTime?.split(".")[0].split(" ")[1];
        let date = serverTime?.split(".")[0].split(" ")[0];
        //  console.log(serverTime,today)
        return {
          cashPending: item?.cashOutAmount,
          createdAt: date,
          id: item?.id,
          reason: item?.reason,
          doneBy: item?.doneBy?.name,
          time: today,
          storeName: item?.store?.storename,
        };
      });
      setCvcData(GRNCVC);
    }
  }, [cashoutReport]);

  return (
    <div>
      <Breadcrumbs />

      <Box
        direction="row"
        spacing={2}
        key={location?.pathname}
        sx={{ display: "flex", mb: 2 }}
      >
        <Formik
          initialValues={initialValues}
          // validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            setInitialValue(values);
            if (location?.pathname === "/cash-out-report") {
              if (getUserTypeFromLs() === "SA")
                dispatch(getCashoutReport(values, csvDownloadCashout));
              else if (values.storeId)
                dispatch(getCashoutReport(values, csvDownloadCashout));
              else Utils.showAlert(2, "Please select store");
            } else if (location?.pathname === "/pending-cash-report") {
              dispatch(getCashoutPendingReport(values, csvDownload));
            } else if (location?.pathname === "/stock-ledger") {
              alert("Under development");
              // dispatch(getStoreGrnReport(values, csvDownload));
            } else if (location?.pathname === "/grn-report") {
              // alert("Under development");
              dispatch(getStoreGrnReport(values));
            } else if (location?.pathname === "/rtv-report") {
              // alert("Under development");

              dispatch(getStoreRtvReport(values));
            } else if (location?.pathname === "/discard-report") {
              if (!values?.storeId) {
                Utils.showAlert(2, "Please select store");
                return;
              }
              dispatch(getStoreDiscardRtvReport(values));
            } else if (location?.pathname === "/billing-report") {
              if (permissions && !values.storeId) {
                setSubmitting(true);
                Utils.showAlert(2, "Please select Store");
                return;
              }
              dispatch(generatReport(values, setSubmitting));
            } else if (location?.pathname === "/return-report") {
              if (!values?.storeId) {
                Utils.showAlert(2, "Please select store");
                return;
              }
              dispatch(getReturnReport(values));
              // dispatch(getStoreGrnReport(values, csvDownload));
            }
            // dispatch(getSKUTimeline(values));
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}  className="w-100">
              <Grid
                container
                rowSpacing={3}
                columnSpacing={2}
                className="m-t-5"
              >
                <Grid item xs={6} sm={3} lg={4}>
                  <AutoComplete
                    freeSolo={false}
                    // className="w-300"
                    // onBlur={handleBlur}
                    values={values.storeId}
                    onChange={(event, value) => {
                      if (value !== null) {
                        const index = storeListForDropdown.findIndex(
                          (x) => x.id === value.id
                        );

                        setFieldValue("storeId", value?.id);
                        setFieldValue("storeName", value?.storename);
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"Store Name"}
                    touched={touched.storeId}
                    options={storeListForDropdown}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = storeListForDropdown?.findIndex(
                        (x) => x?.id == values.storeId
                      );
                      return storeListForDropdown[index];
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={4} lg={3}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Grid>
                <Grid item xs={8} sm={4} lg={3}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Grid>
                <Grid item xs={3} sm={2} lg={2} className="p-l-0">
                  <NormalButton
                    className="btn-purple fend h-100"
                    buttonText="Generate Report"
                    // onPress={() => {}}
                    type="submit"
                  />
                </Grid>
              </Grid>
              <hr className="m-t-20 m-b-20 m-l-0" />
              {/* <div className="Dflex al-cnt fl-end"></div> */}
            </Form>
          )}
        </Formik>
      </Box>
      {/* <Divider /> */}
      <CSVLink
        data={cvcData}
        headers={headers}
        filename={"CashpendingReport"}
        ref={csvDownload}
        target="_blank"
      />
      <CSVLink
        data={cvcData}
        headers={headers}
        filename={"CashoutReport"}
        ref={csvDownloadCashout}
        target="_blank"
      />
    </div>
  );
}
