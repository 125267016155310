import React from "react";
import Button from "@mui/material/Button";
import Schema from "../../../schema";
import AutocompleteMultiple from "../../../component/input/multipleAutocomplete";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { updatePropertyOwner, createPropertyOwner } from "./action";
import Input from "../../../component/input";
import NormalButton from "../../../component/button";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();

  const { storeListForDropdown } = useSelector((state) => state.commonReducer);
  const initialValues = {
    store: isEdit?.length ? isEdit[0].stores : [],
    propertyOwner: isEdit?.length ? isEdit[0]?.user?.id : "",
    bankAccountNumber: isEdit?.length ? isEdit[0].bankAccountNumber : "",
    bankIFSC: isEdit?.length ? isEdit[0].bankIFSC : "",
    bankBranch: isEdit?.length ? isEdit[0].bankBranch : "",
    bankName: isEdit?.length ? isEdit[0].bankName : "",
    CIN: isEdit?.length ? isEdit[0].CIN : "",
    GST: isEdit?.length ? isEdit[0].GST : "",
  };
  // const { propertyOwnerList } = useSelector(
  //   (state) => state.propertyOwnerReducer
  // );
  const { userlistForDropdown } = useSelector((state) => state.commonReducer);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.propertyOwnerSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (isEdit?.length) {
          dispatch(
            updatePropertyOwner(
              values,
              setOpen,
              isEdit[0].id,
              resetForm,
              setIsEdit
            )
          );
        } else {
          dispatch(createPropertyOwner(values, setOpen));
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <AutoComplete
                freeSolo={false}
                // onBlur={handleBlur}
                values={values.propertyOwner}
                onChange={(event, value) => {
                  if (value !== null) {
                    // const index = propertyOwnerList.findIndex(
                    //   (x) => x.id === value.id
                    // );

                    setFieldValue("propertyOwner", value?.id);
                  }
                }}
                helperText={touched.propertyOwner && errors.propertyOwner}
                error={Boolean(touched.propertyOwner && errors.propertyOwner)}
                name={"propertyOwner"}
                label={"Property Owner"}
                touched={touched.propertyOwner}
                options={userlistForDropdown}
                getOptionLabel={(option) => option?.name}
                // isRequired={true}
                getOptionSelected={(option, value) => {
                  return `${option.id}` === `${value.id}`;
                }}
                defaultValue={() => {
                  const index = userlistForDropdown?.findIndex(
                    (x) => x?.id == values.propertyOwner
                  );
                  return userlistForDropdown[index];
                }}
              />{" "}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <AutocompleteMultiple
                values={values.store}
                onInputChange={(event) => {
                  if (event !== null) {
                  }
                }}
                onChange={(event, value) => {
                  if (value !== null) {
                    const index = storeListForDropdown.findIndex((x) =>
                      // x.id === value[0].id
                      {
                        return value.map((item) => item.id === x.id);
                      }
                    );
                    handleChange(event);
                    if (index >= 0) {
                      setFieldValue(
                        "store",
                        value.map((item) => `${item.id}`)
                      );
                    }
                  }
                }}
                helperText={touched.store && errors.store}
                error={Boolean(touched.store && errors.store)}
                name={"store"}
                label={"Store"}
                touched={touched.store}
                options={storeListForDropdown}
                getOptionLabel={(option) => option.storename}
                // isRequired={true}
                getOptionSelected={(option, value) => {
                  return `${option.id}` === `${value.id}`;
                }}
                defaultValue={
                  values?.store?.length > 0
                    ? () => {
                        const items = [];
                        storeListForDropdown.forEach((x) => {
                          if (
                            values.store &&
                            values.store.includes(`${x.id}`)
                          ) {
                            return items.push(x);
                          }
                        });
                        return items;
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.bankAccountNumber}
                name="bankAccountNumber"
                type="text"
                maxLength={10}
                error={errors.bankAccountNumber}
                label="Bank Account Number"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.bankIFSC}
                name="bankIFSC"
                type="text"
                error={errors.bankIFSC}
                label="Bank IFSC"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.bankBranch}
                name="bankBranch"
                type="bankBranch"
                error={errors.bankBranch}
                label="Bank Branch"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.bankName}
                name="bankName"
                type="text"
                error={errors.bankName}
                label="Bank Name"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.CIN}
                name="CIN"
                type="text"
                error={errors.CIN}
                label="CIN"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.GST}
                name="GST"
                type="text"
                error={errors.GST}
                label="GST"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>
          {/* <Button variant="outlined" type="submit">
            {isEdit?.length ? "update Property Owner" : "Property Owner"}
          </Button> */}
          <hr className="m-t-20 m-b-20" />
          <div className="Dflex al-cnt fl-end">
            <NormalButton
              className="btn-simple"
              buttonText="Cancel"
              onPress={() => setOpen(false)}
            />
            <NormalButton
              className="btn-purple m-l-30"
              buttonText={
                isEdit?.length ? "update Property Owner" : "Property Owner"
              }
              // onPress={() => {}}
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
