import * as React from "react";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function ImportedButton({
  text,
  onPress = () => {
    document.getElementById("impotXlsxButton")?.click();
  },
  handleFile,
  fileName,
}) {
  return (
    <>
      <input
        type="file"
        hidden
        id="impotXlsxButton"
        onChange={handleFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Box
        sx={{
          display: "flex",
          // mb: 2,
          borderRadius: 5,
          cursor: "pointer",
          mt:1,
          alignItems: "center",
          justifyContent: "center",
          border: "1px dotted black",
          // width: 300,
          p: 5,
        }}
        onClick={onPress}
        // disabled={disabled}
      >
        <div style={{ textAlign: "center" }}>
          <CloudUploadIcon />
          <br />
          {fileName?.name
            ? fileName?.name
            : " Click here to upload file"}
        </div>
      </Box>
      {/* <Button
        // color="primary"
        sx ={{width:300}}
        variant="outlined"
        onClick={onPress}
        disabled={disabled}
      >
        {text}
      </Button> */}
    </>
  );
}
