import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { fileUpload } from "../../productRelated/products/action";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { updateStoreStock } from "./action";
import Input from "../../../component/input";
import { Box, Grid } from "@mui/material";
import { getPOById } from "../po/action";
import Select from "../../../component/select";
import Utils from "../../../utility";
import UploadImage from "../../../component/uploadImage";
import moment from "moment";
import NormalButton from "../../../component/button";

const AddUser = ({ setOpen, openEditSS, storeId }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);

  const initialValues = {
    quantity: +openEditSS?.quantity,
    printedMrp: +openEditSS?.printedMrp,
    sellingPrice: +openEditSS?.sellingPrice,
    productCostPrice: +openEditSS?.productCostPrice,
  };
  // console.log(openEditSS, "openEditSS");
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={Schema.storeStockSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          dispatch(
            updateStoreStock(
              values,
              openEditSS?.id,
              setOpen,
              storeId,
              setSubmitting
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
              <Grid item xs={12} sm={6} lg={4}>
                <Input
                  value={openEditSS?.product?.name}
                  name="productName"
                  type="text"
                  disabled={true}
                  // error={errors.quantity}
                  label="Product Name"
                  // onChange={handleChange}
                  // handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Input
                  value={values.quantity}
                  name="quantity"
                  type="number"
                  disabled={
                    localStorage.getItem("username") == "mani" ? false : true
                  }
                  error={errors.quantity}
                  label="Quantity"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Input
                  value={values.printedMrp}
                  name="printedMrp"
                  type="number"
                  error={errors.printedMrp}
                  label="MRP"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
              <Grid item xs={12} sm={6} lg={4}>
                <Input
                  value={values.sellingPrice}
                  name="sellingPrice"
                  type="number"
                  error={errors.sellingPrice}
                  label="Selling Price"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Input
                  value={values.productCostPrice}
                  name="productCostPrice"
                  type="number"
                  error={errors.productCostPrice}
                  label="Cost Price"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
            </Grid>

            <hr className="m-t-20 m-b-20" />

            <div className="Dflex al-cnt fl-end">
              <NormalButton
                className="btn-simple"
                buttonText="Cancel"
                onPress={() => setOpen(false)}
              />
              <NormalButton
                className="btn-purple m-l-30"
                buttonText="Update store stock"
                // onPress={() => {}}
                type="submit"
              />
            </div>
            {/* <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
              <Button variant="outlined" type="submit" disabled={isSubmitting}>
                Update store stock
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                Cancel
              </Button>
            </Box> */}
          </Form>
        )}
      </Formik>
    </>
    //  </Authlayout>
  );
};

export default AddUser;
