import api from "./api";
import showAlert from "./alert";
import endPoints from "./endPoints";
import constants from "./constant";
import ActionName from "./actionName";
import commonFunction from "./commonFunction";
import { CloseIMG } from "./images";
export * from "./images";

const Utils = {
  //   routes,
  //   images: LocalImages,
  CloseIMG,
  api: api,
  showAlert: showAlert,
  endPoints: endPoints,
  constants: constants,
  ActionName: ActionName,
  commonFunction: commonFunction,
};

export default Utils;
