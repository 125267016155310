import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function MaterialUIPickers({
  handleChange,
  value,
  label,
  name,
  error,
  maxDate,
  minDate,
}) {
  //   const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  //   const handleChange = (newValue) => {
  //     setValue(newValue);
  //   };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ zIndex: 99999 }}>
      <Stack
        spacing={3}
        className="datepicker"
        sx={{
          width: "100%",
          pr: 2,
          maxWidth: "100%",
        }}
      >
        <DesktopDatePicker
          label={label}
          name={name}
          inputFormat="dd/MM/yyyy"
          value={value}
          maxDate={maxDate}
          minDate={minDate}
          // sx={{ zIndex: 99999 }}
          invalidDateMessage={error}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              size="small"
              minDate={minDate}
              maxDate={maxDate}
              invalidDateMessage={error}
              name={name}
              helperText={error}
              onError={true}
              // sx={{ zIndex: 99999 }}
              error={true}
              {...params}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
