import * as React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getRtvStoreStockList } from "../../pages/storeRelated/rtvStocks/action";

import {
  getRtvWarehouseStockList,
  verifyRtvWarehouse,
} from "../../pages/warehouseRelated/rtvStocks/action";

import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { discardInvoice } from "../../pages/storeRelated/rtvStocks/action";
import Utils from "../../utility";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
    sorting: false,
  },
  {
    id: "fromStore",
    numeric: true,
    disablePadding: true,
    label: "From Store",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: true,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Product Barcode",
    sorting: false,
  },
  {
    id: "Vendor ID",
    numeric: false,
    disablePadding: true,
    label: "Brand",
  },
  {
    id: "Vendor",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
    sorting: false,
  },
  {
    id: "Excess/Short",
    numeric: false,
    disablePadding: true,
    label: "Done by",
    sorting: false,
  },
  {
    id: "Invoice Number",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  {
    id: "Store",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isWarehouseRoutes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : isWarehouseRoutes === "/discard-warehouse-stocks" &&
              headCell.id === "fromStore" ? (
              "From warehouse"
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ isStoreDiscard, selectId }) {
  let isWarehouseRoutes = useLocation().pathname;
  const dispatch = useDispatch();
  const { order, sortField } = useSelector((state) => state.GRNReducer);
  const { rtvStoreStock } = useSelector((state) => state.rtvStoreStockReducer);
  React.useEffect(() => {
    return () => {
      dispatch({
        type: Utils.ActionName.RTV_STORE_STOCK,
        payload: {
          rtvStoreStock: [],
          type: "",
          order: "DESC",
          sortField: "userName",
          offset: 1,
          take: 10,
          search: "",
          count: 0,
        },
      });
    };
  }, []);
  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={sortField}
            isWarehouseRoutes={isWarehouseRoutes}
          />
          <TableBody>
            {rtvStoreStock.length &&
              rtvStoreStock.map((row, index) => {
                return (
                  <TableRow hover key={row?.id}>
                    <TableCell component="th" id={index} scope="row">
                      {row?.id}
                    </TableCell>
                    <TableCell align="left">
                      {row?.rtvStocks?.store?.storename ||
                        row?.rtvStocks?.warehouse?.warehouseNickname}
                    </TableCell>
                    <TableCell align="left">{row?.product?.name}</TableCell>
                    <TableCell align="left">{row?.product?.barcode}</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">{row?.quantity}</TableCell>
                    <TableCell align="left">
                      {row?.rtvStocks?.doneBy?.name}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row?.rtvStocks?.addDate).format("DD-MM-YYYY")}
                    </TableCell>
                    {/* <TableCell align="left">{row?.invoiceNumber}</TableCell> */}
                    <TableCell align="left">
                      <>
                        {row?.approvedStatus !== "approved" && (
                          <VerifiedIcon
                            onClick={() =>
                              dispatch(
                                verifyRtvWarehouse(row?.id, () => {
                                  if (isStoreDiscard) {
                                    dispatch(
                                      getRtvStoreStockList(selectId, true)
                                    );
                                  } else
                                    dispatch(
                                      getRtvWarehouseStockList(
                                        selectId,
                                        "warehouse",
                                        true
                                      )
                                    );
                                })
                              )
                            }
                          />
                        )}
                      </>
                      {isStoreDiscard &&
                        ["SA", "PO"].includes(
                          localStorage.getItem("userType")
                        ) && (
                          <VisibilityIcon
                            onClick={() => {
                              dispatch(discardInvoice(row?.rtvStocks?.id));
                            }}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
