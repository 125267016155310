import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./select.scss";
export default function SelectOtherProps({
  handleChange,
  value,
  label,
  option,
  name,
  error,
  minWidth,
  disabled,
}) {
  return (
    <FormControl error={error} size="small">
      <InputLabel id="demo-simple-select-disabled-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-disabled-label"
        id="demo-simple-select-disabled"
        value={value}
        disabled={disabled}
        label={label}
        onChange={handleChange}
        name={name}
        className="zindex99999"
      >
        {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
        {option}
        {/* {option.map()}
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
