import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Login from "../screens/login";
import PublicAuth from "./publicAuth";
import RequireAuth from "./RequireAuth";
import Login from "../pages/auth/login.js";
import DiscordWarehouseStocks from "../pages/warehouseRelated/discordWarehouseStock";
import Dashboard from "../pages/dashboard/index.js";
import Users from "../pages/userAccount/users";
import PropertyOwner from "../pages/userAccount/propertyOwner";
import MainLayout from "../component/layout/MainLayout";
import Brands from "../pages/productRelated/brands";
import Recipes from "../pages/productRelated/recipes";
// import Store from "../pages/storeRelated/store";
import Warehouse from "../pages/warehouseRelated/warehouses";
import Credit from "../pages/reports/credit";
import WarehouseStock from "../pages/warehouseRelated/warehouseStocks";
import StoreStocks from "../pages/storeRelated/storeStocks";
import StoreGRN from "../pages/storeRelated/storeGRN";
import GRN from "../pages/storeRelated/GRN";
import WarehouseToStore from "../pages/storeTransfers/warehouseToStore";
import WarehouseToWarehouse from "../pages/storeTransfers/warehouseToWarehouse";
import StoreToStore from "../pages/storeTransfers/storeToStore";
import StoreToWarehouse from "../pages/storeTransfers/storeToWarehouse";
import WarehouseGRN from "../pages/warehouseRelated/warehouseGRN";
import SKUTimeline from "../pages/storeRelated/SKUTimeline";
import SKUTimelineWarehouse from "../pages/warehouseRelated/SKUTimeline";
import RTVStocks from "../pages/storeRelated/rtvStocks";
import RTVStocksWarehouse from "../pages/warehouseRelated/rtvStocks";
import AppVersion from "../pages/superAdmin/appVersion";
import Orders from "../pages/invoiceAndBilling/order";
import ReturnOrder from "../pages/invoiceAndBilling/return";
import PurcheseOrder from "../pages/storeRelated/po";
import DiscordStoreStock from "../pages/storeRelated/discordStoreStock";
import Banner from "../pages/superAdmin/banner";
import OnlineOrder from "../pages/superAdmin/onlineOrder";
import WarehousePo from "../pages/warehouseRelated/warehousePo";
import WarehouseReport from "../pages/storeRelated/pendingAndCashoutReport/warehouseReports";
import StoreReport from "../pages/storeRelated/pendingAndCashoutReport/storeReports";
import DeliveryDetailPage from "../pages/superAdmin/onlineOrder/deliveryDetail";
import EditCostAndSellingPrice from "../pages/storeRelated/editCostSellingPrice";
import StockLedger from "../pages/storeRelated/stockLedger";
import { useEffect, Suspense, lazy } from "react";
import MainSkeleton from "../component/skeleton/headerFooter";
import CurrentStockReport from "../pages/storeRelated/reports/currentStockReport.js";
// import Products from "../pages/productRelated/products";
// import Category from "../pages/productRelated/productCategory";
// import BrandOwner from "../pages/userAccount/brandOwner.js";
// import StoreDetail from "../pages/storeRelated/store/storeDetail";

const Store = lazy(() => import("../pages/storeRelated/store"));
const StoreRoyalty = lazy(() =>
  import("../pages/storeRelated/store/storeDetail")
);
const Products = lazy(() => import("../pages/productRelated/products"));
const Category = lazy(() => import("../pages/productRelated/productCategory"));
const BrandOwner = lazy(() => import("../pages/userAccount/brandOwner.js"));
const Customer = lazy(() => import("../pages/userAccount/customer"));
const RoyaltyHistory = lazy(() =>
  import("../pages/storeRelated/store/royaltyHistory.js")
);

const Profile = lazy(() => import("../pages/profile/index.js"));

const AddStoreStock = lazy(() =>
  import("../pages/storeRelated/storeStocks/addstoreStocks")
);
// const RedirectToHTTPS = () => {
// 	const currentHostname = window.location.hostname;
// const RedirectToHTTPS = () => {
//   const currentHostname = window.location.hostname;

//   if (
//     currentHostname === "localhost" ||
//     window.location.href === "https://uatadmin.thenewshop.in/"
//   ) {
//     return;
//   } else if (
//     window.location.href === "http://admin.thenewshop.in/" ||
//     window.location.protocol === "http:"
//   ) {
//     const httpsURL = window.location.href.replace("http:", "https:");
//     window.location.href = httpsURL;
//   } else if (
//     window.location.href === "http://uatadmin.thenewshop.in/" ||
//     window.location.protocol === "http:"
//   ) {
//     const httpsURL = window.location.href.replace("http:", "https:");
//     window.location.href = httpsURL;
//   } else {
//     return;
//   }
//   return null;
// };

function Router() {
  // useEffect(() => {
  //   RedirectToHTTPS();
  // }, []);
  return (
    <Suspense fallback={<MainSkeleton />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PublicAuth>
                <Login />
              </PublicAuth>
            }
          />
          <Route
            path="/detail-page-for-delivery/:id"
            element={<DeliveryDetailPage />}
          />

          <Route element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/property-owner"
              element={
                <RequireAuth>
                  <PropertyOwner />
                </RequireAuth>
              }
            />
            <Route
              path="/brands"
              element={
                <RequireAuth>
                  <Brands />
                </RequireAuth>
              }
            />
            <Route
              path="/store"
              element={
                <RequireAuth>
                  <Store />
                </RequireAuth>
              }
            />
            <Route
              path="/store-royalty"
              element={
                <RequireAuth>
                  <StoreRoyalty />
                </RequireAuth>
              }
            />
            <Route
              path="/payment-royalty"
              element={
                <RequireAuth>
                  <StoreRoyalty />
                </RequireAuth>
              }
            />

            <Route
              path="/royalty-history"
              element={
                <RequireAuth>
                  <RoyaltyHistory />
                </RequireAuth>
              }
            />

            <Route
              path="/warehouse"
              element={
                <RequireAuth>
                  <Warehouse />
                </RequireAuth>
              }
            />
            <Route
              path="/products"
              element={
                <RequireAuth>
                  <Products />
                </RequireAuth>
              }
            />
            <Route
              path="/category"
              element={
                <RequireAuth>
                  <Category />
                </RequireAuth>
              }
            />
            <Route
              path="/customer"
              element={
                <RequireAuth>
                  <Customer />
                </RequireAuth>
              }
            />
            <Route
              path="/my-profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/brand-owner"
              element={
                <RequireAuth>
                  <BrandOwner />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-stock"
              element={
                <RequireAuth>
                  <WarehouseStock />
                </RequireAuth>
              }
            />
            {/* <Route
              path="/store-stocks"
              element={
                <RequireAuth>
                  <StoreStocks />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/grn"
              element={
                <RequireAuth>
                  <GRN />
                </RequireAuth>
              }
            />
            <Route
              path="/add-grn"
              element={
                <RequireAuth>
                  <AddStoreStock />
                </RequireAuth>
              }
            />
            <Route
              path="/store-grn"
              element={
                <RequireAuth>
                  <StoreGRN />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-grn"
              element={
                <RequireAuth>
                  <WarehouseGRN />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-to-store"
              element={
                <RequireAuth>
                  <WarehouseToStore />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-to-warehouse"
              element={
                <RequireAuth>
                  <WarehouseToWarehouse />
                </RequireAuth>
              }
            />
            <Route
              path="/store-to-warehouse"
              element={
                <RequireAuth>
                  <StoreToWarehouse />
                </RequireAuth>
              }
            />
            <Route
              path="/store-to-store"
              element={
                <RequireAuth>
                  <StoreToStore />
                </RequireAuth>
              }
            />
            <Route
              path="/SKU-Timeline"
              element={
                <RequireAuth>
                  <SKUTimeline />
                </RequireAuth>
              }
            />

            <Route
              path="/SKU-Timeline-warehouse"
              element={
                <RequireAuth>
                  <SKUTimelineWarehouse />
                </RequireAuth>
              }
            />

            <Route
              path="/rtvstocks"
              element={
                <RequireAuth>
                  <RTVStocks />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-rtvstocks"
              element={
                <RequireAuth>
                  <RTVStocksWarehouse />
                </RequireAuth>
              }
            />
            <Route
              path="/discard-warehouse-stocks"
              element={
                <RequireAuth>
                  <DiscordWarehouseStocks />
                </RequireAuth>
              }
            />
            <Route
              path="/discard-store-stocks"
              element={
                <RequireAuth>
                  <DiscordStoreStock />
                </RequireAuth>
              }
            />
            <Route
              path="/order"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
            <Route
              path="/order-return"
              element={
                <RequireAuth>
                  <ReturnOrder />
                </RequireAuth>
              }
            />
            <Route
              path="/store-po"
              element={
                <RequireAuth>
                  <PurcheseOrder />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-po"
              element={
                <RequireAuth>
                  <WarehousePo />
                </RequireAuth>
              }
            />
            <Route
              path="/app-version"
              element={
                <RequireAuth>
                  <AppVersion />
                </RequireAuth>
              }
            />
            <Route
              path="/banner"
              element={
                <RequireAuth>
                  <Banner />
                </RequireAuth>
              }
            />
            <Route
              path="/online-order"
              element={
                <RequireAuth>
                  <OnlineOrder />
                </RequireAuth>
              }
            />
            <Route
              path="/cash-out-report"
              element={
                <RequireAuth>
                  <StoreReport />
                </RequireAuth>
              }
            />
            <Route
              path="/credit"
              element={
                <RequireAuth>
                  <Credit />
                </RequireAuth>
              }
            />

            <Route
              path="/rtv-report"
              element={
                <RequireAuth>
                  <RTVStocks />
                </RequireAuth>
              }
            />
            <Route
              path="/discard-report"
              element={
                <RequireAuth>
                  {/* <StoreReport /> */}
                  <DiscordStoreStock />
                </RequireAuth>
              }
            />
            <Route
              path="/billing-report"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
            <Route
              path="/pending-cash-report"
              element={
                <RequireAuth>
                  <StoreReport />
                </RequireAuth>
              }
            />
            <Route
              path="/stock-ledger"
              element={
                <RequireAuth>
                  <StockLedger />
                </RequireAuth>
              }
            />
            <Route
              path="/pending-cash-report"
              element={
                <RequireAuth>
                  <StoreReport />
                </RequireAuth>
              }
            />
            <Route
              path="/current-stock-report"
              element={
                <RequireAuth>
                  {/* <CurrentStockReport /> */}
                  <StoreStocks />
                </RequireAuth>
              }
            />
            <Route
              path="/grn-report"
              element={
                <RequireAuth>
                  <StoreReport />
                </RequireAuth>
              }
            />
            <Route
              path="/return-report"
              element={
                <RequireAuth>
                  <ReturnOrder />
                </RequireAuth>
              }
            />
            <Route
              path="/warehouse-grn-report"
              element={
                <RequireAuth>
                  <WarehouseReport />
                </RequireAuth>
              }
            />
            <Route
              path="/recipes"
              element={
                <RequireAuth>
                  <Recipes />
                </RequireAuth>
              }
            />
            {/* <Route
            path="/edit-cost-price"
            element={<EditCostAndSellingPrice />}
          /> */}
            <Route
              path="/edit-cost-selling-price"
              element={<EditCostAndSellingPrice />}
            />
            <Route path="*" element={<h1>Not found</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
