import * as React from "react";
import { useState } from "react";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import moment from "moment";
import AddProductDrawer from "../../../component/drawer";

import ProductSkeleton from "../../../component/skeleton/table";
import useDebounce from "../../../hooks/debounce";
import Utils from "../../../utility";
import { deleteBrand, getBrandList } from "./action";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import AddButton from "../../../component/button/buttonWithIcon";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchInput from "../../../component/searchInput";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import Modal from "../../component/modal"
import AddUser from "./addBrand";
import Breadcrumbs from "../../../component/breadcrumb";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortDisable: false,
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Date created",
    sortDisable: true,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sortDisable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sortDisable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Brands
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  // const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const { brands, order, sortField, offset, take, search, count } = useSelector(
    (state) => state.brandReducer
  );
  const debouncedSearch = useDebounce(search, 500);
  const { loading } = useSelector((state) => state.loaderReducer);

  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  React.useEffect(() => {
    dispatch(getBrandList());
  }, [debouncedSearch]);
  React.useEffect(() => {
    return () => {
      dispatch({ type: Utils.ActionName.RESET_BRAND });
    };
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.BRAND,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getBrandList());
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.BRAND,
      payload: { offset: newPage + 1 },
    });
    dispatch(getBrandList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.BRAND,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getBrandList());
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  //  serching data in table
  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.BRAND,
      payload: { search: e.target.value, take: take, offset: 1 },
    });
    // dispatch(getBrandList());
  };

  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* {permissions && permissions["MANAGE_USERS"].edit ? ( */}
          {!permissions || permissions["brand"]?.add ? (
            <AddButton
              text="Add"
              className="btn-purple m-l-10"
              onPress={() => setOpen(true)}
            />
          ) : (
            <div></div>
          )}
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              // clearSearch={handleSearchClear}
            />
            {/* 
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={count}
              color="primary"
            >
              <IconLabelButtons
                ButtonIcon={<FilterListIcon />}
                onPress={filterPress}
                className="filterNv"
              />
            </Badge> */}
          </Box>
        </div>
        {/* <TableContainer></TableContainer> */}
        {/* <Box sx={{ width: "100%" }}> */}

        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Stack direction="row" spacing={2}>
            {!permissions || permissions["brand"]?.add ? (
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setOpen(true)}
              >
                Add Brands
              </Button>
            ) : (
              <></>
            )}
          </Stack>
          <TextField
            id="standard-search"
            label="Search field"
            type="search"
            variant="standard"
            value={search}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div> */}
        <TableContainer>
          {/* <EnhancedTableToolbar /> */}

          <Table
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              // numSelected={selected.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              rowCount={brands.length}
            />
            

            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

              {!loading ? (
                brands.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={index}
                        scope="row"
                        // padding="none"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.brandName}</TableCell>
                      <TableCell align="left">
                        {moment(row?.addDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        <>
                          {!permissions || permissions["brand"]?.edit ? (
                            <EditIcon
                              onClick={() => {
                                setIsEdit(
                                  brands.filter((item) => item.id === row.id)
                                );
                                setOpen(true);
                              }}
                            />
                          ) : (
                            <></>
                          )}{" "}
                          {!permissions || permissions["brand"]?.delete ? (
                            <DeleteIcon
                              onClick={() => {
                                setDeleteConfirmation(row.id);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <ProductSkeleton />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {/* <Modal
        header={`${isEdit ? "Edit" : "Add"} Brand`}
        children={
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      /> */}
      <AddProductDrawer
        state={open}
        setState={setOpen}
        // className="notificationDrawer"
      >
        <>
          <div className="niti_header">
            <Typography variant="body2" className="semi-bold">
              Add Brand
            </Typography>
            <hr className="m-t-20 m-b-20" />
          </div>
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        </>
      </AddProductDrawer>
      <DeleteConfirmationPopup
        title="Brand"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deleteBrand(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
    </>
  );
}
