import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { addIngredient, getProductDetail } from "./action";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
import NormalButton from "../../../component/button";
import Grid from "@mui/material/Grid";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import TextField from "@mui/material/TextField";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
// import { createStoreGRN } from "./action";
import Input from "../../../component/input";
import { Box, Divider, Typography } from "@mui/material";
// import { getPOById } from "../po/action";
import Select from "../../../component/select";
// import Utils from "../../../utility";
// import UploadImage from "../../../component/uploadImage";
// import moment from "moment";

// import Select from "../../../component/select";
// import Utils from "../../../utility";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  const { recipesDetail } = useSelector((state) => state.recipesReducer);

  // CATALOG_MANAGER =  'CAM',
  // ACCOUNTS_MANAGER = 'AM',
  // OPERATIONS_MANAGER = 'OM',
  // RUNNER = 'R',
  // CUSTOMER = 'C'
  // }

  // const validateFields = (event) => {
  //   if (event.target.name === "email") {
  //     setUsernameValid(emailValidator(emailId));
  //   }

  //   if (event.target.name === "password") {
  //     setPasswordValid(passwordValidator(password));
  //   }
  // };

  const initialValues = {
    recipe: "",
    product: recipesDetail?.recipe?.length
      ? recipesDetail?.recipe?.map((item) => {
          return {
            product: item?.product?.id || null,
            quantity: item?.quantity || null,
            unit: item.unit || null,
          };
        })
      : [{ product: null, quantity: null, unit: null }],
  };
  const {
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.RecipeSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(addIngredient(values, setOpen));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
            <h4 style={{ fontSize: 17, margin: 0 }}>
              {`${recipesDetail?.name} (${recipesDetail?.product?.barcode})`}{" "}
            </h4>
            {/* <AutoComplete
              freeSolo={false}
              values={values.recipe}
              onChange={(event, value) => {
                if (value !== null) {
                  const pIndex = productsDropDownList.findIndex(
                    (x) => x.id === value.id
                  );
                  setFieldValue(`recipe`, value?.id);
                }
              }}
              helperText={touched?.recipe && errors?.recipe}
              error={Boolean(touched?.recipe && errors?.recipe)}
              name={`recipe`}
              label={"Chose Product"}
              // touched={touched.recipe}
              options={productsDropDownList}
              getOptionLabel={(option) => `${option?.barcode} | ${option.name}`}
              // isRequired={true}
              getOptionSelected={(option, value) => {
                return `${option.id}` === `${value?.id}`;
              }}
              defaultValue={() => {
                const pIndex = productsDropDownList.findIndex(
                  (x) => x?.id == values.recipe
                );
                return productsDropDownList[pIndex];
              }}
              // isDisabled={isDisabled}
            /> */}
          </Box>

          <Divider />
          {/* <h4 style={{ fontSize: 17 }}>Ingredients</h4> */}
          <Typography id="transition-modal-description" sx={{ my: 2 }}>
            Ingredients
          </Typography>
          <FieldArray
            name="product"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values?.product && values?.product.length > 0
                    ? values.product.map((val, index) => (
                        <Box sx={{ display: "flex", mb: 2 }}>
                          <Grid container rowSpacing={3} columnSpacing={2}>
                            <Grid item xs={12} sm={6} lg={4} key={val?.barcode}>
                              <AutoComplete
                                freeSolo={false}
                                values={values.product[index].product}
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    // const pIndex = productsDropDownList.findIndex(
                                    //   (x) => x.id === value.id
                                    // );
                                    setFieldValue(
                                      `product.${index}.product`,
                                      value?.id
                                    );
                                  }
                                  dispatch(
                                    getProductDetail(value?.id, (res) => {
                                      setFieldValue(
                                        `product.${index}.unit`,
                                        res?.unit
                                      );
                                      // handleChange({
                                      //   name: `product.${index}.quantity`,
                                      //   value: res?.quantity,
                                      // });
                                    })
                                  );
                                }}
                                helperText={
                                  touched?.product &&
                                  errors?.product &&
                                  errors?.product[index]?.product
                                }
                                error={Boolean(
                                  touched?.product &&
                                    errors?.product &&
                                    errors?.product[index]?.product
                                )}
                                name={`product.${index}.product`}
                                label={"Product Name"}
                                // touched={touched.product}
                                options={productsDropDownList}
                                getOptionLabel={(option) =>
                                  `${option?.barcode} | ${option.name}`
                                }
                                // isRequired={true}
                                getOptionSelected={(option, value) => {
                                  return `${option.id}` === `${value?.id}`;
                                }}
                                defaultValue={() => {
                                  const pIndex = productsDropDownList.findIndex(
                                    (x) =>
                                      x?.id === values.product[index].product
                                  );
                                  return productsDropDownList[pIndex];
                                }}
                                // isDisabled={isDisabled}
                              />
                            </Grid>

                            {values.product[index].unit ? (
                              <>
                                <Grid item xs={12} sm={6} lg={4}>
                                  <Input
                                    value={values.product[index].quantity}
                                    name={`product.${index}.quantity`}
                                    type="number"
                                    disabled={!values.product[index].product}
                                    error={
                                      errors.product &&
                                      errors.product[index]?.quantity
                                    }
                                    // error={errors.product}
                                    label="Percentage of quantity"
                                    onChange={handleChange}
                                    // handleBlur={handleBlur}
                                    // disabled={isDisabled}
                                  />
                                </Grid>

                                <Grid item xs={6} sm={3} lg={2}>
                                  <Select
                                    handleChange={handleChange}
                                    value={values.product[index].unit}
                                    name={`product.${index}.unit`}
                                    label={"Unit"}
                                    option={["pcs", "ml", "gm"]?.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                    error={
                                      errors.product &&
                                      errors.product[index]?.unit
                                    }
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} sm={6} lg={4}>
                                  <Select label={"Quantity"} disabled={true} />
                                </Grid>
                                <Grid item xs={6} sm={3} lg={2}>
                                  <Input
                                    type="number"
                                    disabled={true}
                                    // error={errors.product}
                                    label="Unit"

                                    // disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            )}

                            {/* {!isDisabled && ( */}
                            <Grid item xs={6} sm={3} lg={2}>
                              <IconButton
                                style={{
                                  background: "rgb(231 112 112)",
                                  color: "white",
                                  width: 35,
                                  height: 35,
                                  borderRadius: 5,
                                }}
                                aria-label="delete"
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                style={{
                                  background: "green",
                                  color: "white",
                                  width: 35,
                                  height: 35,
                                  borderRadius: 5,
                                  marginLeft: 1,
                                }}
                                aria-label="delete"
                                size="small"
                                onClick={() =>
                                  arrayHelpers.push({
                                    product: null,
                                    quantity: null,
                                    unit: null,
                                    // mrp: null,
                                  })
                                }
                              >
                                <AddBoxTwoToneIcon fontSize="inherit" />
                              </IconButton>
                            </Grid>
                          </Grid>
                          {/* )} */}
                        </Box>
                      ))
                    : null}
                </div>
              );
            }}
          />

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit">
              {isEdit?.length ? "update Ingredients" : "Add Ingredients"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
