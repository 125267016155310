import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

export default function BasicTable({ rows }) {
  const [rowData, setRowData] = React.useState(rows);
  const [order, setOrder] = React.useState("ASC");
  const [key, setKey] = React.useState("name");
  const handleSorting = (key) => {
    setOrder(order == "ASC" ? "DESC" : "ASC");
    setKey(key);
    const newSortData = [...rowData].sort((a, b) => {
      if (key == "total_sale") {
        if (order == "ASC") {
          if (+a[key] > +b[key]) return -1;
          else return 1;
        } else {
          if (+a[key] > +b[key]) return 1;
          else return -1;
        }
      } else {
        if (order == "ASC") {
          if (a[key]?.trim() > b[key]?.trim()) return -1;
          else return 1;
        } else {
          if (a[key]?.trim() > b[key]?.trim()) return 1;
          else return -1;
        }
      }
    });
    // setPage(1)
    setRowData(newSortData);
  };
  React.useEffect(() => {
    setRowData(rows);
  }, [rows]);
  return (
    <Box className="tableContainer">
      <TableContainer component={Paper} sx={{ m: 1, maxHeight: 400 }}>
        <Table aria-label="simple table">
          <TableHead sx={{ position: "relative" }}>
            <TableRow sx={{ position: "sticky", top: 0 }}>
              <TableCell align="center">S.No</TableCell>
              <TableCell
                align="center"
                onClick={() => handleSorting("brand_name")}
                sx={{ cursor: "pointer" }}
              >
                Name {key == "brand_name" && (order == "DESC" ? "↑" : "↓")}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => handleSorting("total_sale")}
                sx={{ cursor: "pointer" }}
              >
                Total Sale{" "}
                {key == "total_sale" && (order == "DESC" ? "↑" : "↓")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData?.length ? (
              rowData.map((row, i) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center">{row.brand_name}</TableCell>
                  <TableCell align="center">{row.total_sale}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                //   key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" colSpan={3}>
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
