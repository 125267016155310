import * as React from "react";
import { useState } from "react";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import AddIngredients from "./addIngredients";
import useDebounce from "../../../hooks/debounce";
import Utils from "../../../utility";
import {
  deleteBrand,
  getProductsRecipes,
  getRecipes,
  getRecipesDetail,
} from "./action";
import Drawer from "../../../component/drawer";
import AddButton from "../../../component/button/buttonWithIcon";
import SearchInput from "../../../component/searchInput";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import Modal from "../../component/modal"
import AddUser from "./addRecipe";
import Breadcrumbs from "../../../component/breadcrumb";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Recipe Name",
    sortDisable: false,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sortDisable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sortDisable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Recipes
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  // const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [openIngredients, setOpenIngredients] = React.useState(false);
  const [open, setOpen] = useState(false);
  const { recipes, order, sortField, offset, take, search, count } =
    useSelector((state) => state.recipesReducer);
  const debouncedSearch = useDebounce(search, 500);

  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  React.useEffect(() => {
    dispatch(getRecipes());
  }, [debouncedSearch]);
  React.useEffect(() => {
    dispatch(getProductsRecipes());
    return () => {
      dispatch({ type: Utils.ActionName.RECIPES });
    };
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.RECIPES,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getRecipes());
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.RECIPES,
      payload: { offset: newPage + 1 },
    });
    dispatch(getRecipes());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.RECIPES,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getRecipes());
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  //  serching data in table
  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.RECIPES,
      payload: { search: e.target.value, take: take, offset: 1 },
    });
    // dispatch(getRecipes());
  };

  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* {permissions && permissions["MANAGE_USERS"].edit ? ( */}
          {!permissions || permissions["recipes"]?.add ? (
            <AddButton
              text="Add"
              className="btn-purple m-l-10"
              onPress={() => setOpen(true)}
            />
          ) : (
            <div></div>
          )}
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              // clearSearch={handleSearchClear}
            />
            {/* 
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={count}
              color="primary"
            >
              <IconLabelButtons
                ButtonIcon={<FilterListIcon />}
                onPress={filterPress}
                className="filterNv"
              />
            </Badge> */}
          </Box>
        </div>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              // numSelected={selected.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              rowCount={recipes.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

              {recipes.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                    <TableCell
                      component="th"
                      id={index}
                      scope="row"
                      // padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row?.name}</TableCell>
                    {/* <TableCell align="left">
                        {moment(row?.addDate).format("DD-MM-YYYY")}
                      </TableCell> */}
                    <TableCell align="left">
                      <>
                        {!permissions || permissions["recipes"]?.edit ? (
                          <EditIcon
                            onClick={() => {
                              dispatch({
                                type: Utils.ActionName.RECIPES,
                                payload: { recipesDetail: row },
                              });
                              dispatch(
                                getRecipesDetail(row?.id, setOpenIngredients)
                              );
                            }}
                          />
                        ) : (
                          <></>
                        )}{" "}
                        {/* {!permissions || permissions["recipes"]?.delete ? (
                            <DeleteIcon
                              onClick={() => {
                                setDeleteConfirmation(row.id);
                              }}
                            />
                          ) : (
                            <></>
                          )} */}
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {/* <Modal
        header={`${isEdit ? "Edit" : "Add"} Recipe`}
        children={
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      /> */}
      <Drawer state={open} setState={setOpen}>
        <>
          <div className="niti_header">
            <Typography variant="body2" className="semi-bold">
              Add Recipe
            </Typography>
            <hr className="m-t-20 m-b-20" />
          </div>
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        </>
      </Drawer>
      <Modal
        header={`${isEdit ? "Edit" : "Add"} Ingredients`}
        children={<AddIngredients setOpen={setOpenIngredients} />}
        handleClose={() => {
          setOpenIngredients(false);
          setIsEdit(null);
        }}
        open={openIngredients}
        minWidth={700}
      />
      <DeleteConfirmationPopup
        title="Recipe"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          // dispatch(deleteBrand(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
    </>
  );
}
