import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { getStoreDiscardRtvReport } from "./action";
import DatePicker from "../../../component/date";
import { Box } from "@mui/material";
// import { getPOById } from "../po/action";
import Select from "../../../component/select";
import Utils from "../../../utility";
import UploadImage from "../../../component/uploadImage";
import moment from "moment";
import NormalButton from "../../../component/button";

const DailyStcokReport = ({ setOpen, openEditSS, storeId }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);

  const initialValues = {
    storeId: storeId ? storeId : "",
    // fromDate: moment(new Date()).subtract(1, "days"),
    fromDate: new Date(),
    toDate: new Date(),
  };
  const { storeListForDropdown: storeList } = useSelector(
    (state) => state.commonReducer
  );
  // console.log(openEditSS, "openEditSS");
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={Schema.storeStockSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (!values?.storeId) {
            Utils.showAlert(2, "Please select store");
            return;
          }
          dispatch(getStoreDiscardRtvReport(values, setOpen));
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
              <Grid item xs={12} sm={6} lg={4}>
                <AutoComplete
                  freeSolo={false}
                  // onBlur={handleBlur}
                  values={values.store}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setFieldValue("storeId", value?.id);
                      // setFieldValue("storeName", value?.storename);
                    }
                  }}
                  helperText={touched.storeId && errors.storeId}
                  error={Boolean(touched.storeId && errors.storeId)}
                  name={"storeId"}
                  label={"Store Name"}
                  touched={touched.storeId}
                  options={storeList || []}
                  getOptionLabel={(option) => option?.storename}
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option?.id}` === `${value?.id}`;
                  }}
                  defaultValue={() => {
                    if (storeList?.length) {
                      const index = storeList?.findIndex(
                        (x) => x?.id == values.storeId
                      );
                      return storeList[index];
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <DatePicker
                  value={values.fromDate}
                  name="fromDate"
                  handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                  label="From Date"
                  // error={errors.fromDate}
                  // maxDate={new Date(moment(new Date()).subtract(1, "days"))}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <DatePicker
                  value={values.toDate}
                  name="toDate"
                  handleChange={(newVal) => setFieldValue("toDate", newVal)}
                  label="To Date"
                  // error={errors.toDate}
                  // maxDate={new Date(moment(new Date()).subtract(1, "days"))}
                />
              </Grid>
            </Grid>
            <hr className="m-t-20 m-b-20" />
            <div className="Dflex al-cnt fl-end">
              <NormalButton
                className="btn-simple"
                buttonText="Cancel"
                onPress={() => setOpen(false)}
              />
              <NormalButton
                className="btn-purple m-l-30"
                buttonText={"Get report"}
                // onPress={() => {}}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
    //  </Authlayout>
  );
};

export default DailyStcokReport;
